import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import { API_URLS } from '_constants/api';
import history from '_utils/history';
import authTypes from '_actionTypes/auth.types';
import { PATH } from '_routes/Routes';
import authHelpers from '_utils/authHelpers';
import { infoUser, clearSettingsStore } from './mainUser.actions';
import { clearServicesStore } from './services.actions';

// Set logged in user
const setCurrentUser = (decoded) => ({
  type: authTypes.SET_CURRENT_USER,
  payload: decoded,
});

const setToken = (token) => (dispatch) => {
  // Store the token in local storage
  // Set token to Auth header for the axios api request middleware
  authHelpers.setAuthToken(token);
  // Decode token to get user data
  const decoded = jwtDecode(token);

  // Set current user
  dispatch(setCurrentUser(decoded));
  // Pre-fill the other stores
  dispatch(infoUser(decoded.id));

  history.push(PATH.CONNECT.url);
};

const loginRequest = (email, password) => (dispatch) => {
  const action = {
    types: [authTypes.LOGIN_REQUEST, authTypes.LOGIN_SUCCESS, authTypes.LOGIN_FAIL],
    payload: {
      request: {
        url: `${API_URLS.users}/login`,
        method: 'post',
        data: {
          email: email.toLowerCase(),
          password,
        },
        isAuthRequired: false,
      },
    },
  };

  return dispatch(action);
};
const login = (email, password) => (dispatch) => {
  dispatch(loginRequest(email, password))
    .then((response) => {
      // Auth function
      // Could split to helper if re-used elsewhere...
      // Set token to localStorage
      const { token } = response.payload.data;
      dispatch(setToken(token));
    })
    .catch(() => { });
};

export const signUpRequest = (formValues) => (dispatch) => {
  const action = {
    types: [authTypes.SIGNUP_REQUEST, authTypes.SIGNUP_SUCCESS, authTypes.SIGNUP_FAIL], // Axios Redux middleware makes requests and calls the relevant action based on success or failure https://www.npmjs.com/package/redux-axios-middleware
    payload: {
      request: {
        url: `${API_URLS.users}/signup`,
        method: 'post',
        data: {
          ...formValues,
        },
        isAuthRequired: false,
        onError: () => {

        },
      },
    },
  };

  return dispatch(action);
};

const signup = (formValues) => (dispatch) => {
  dispatch(signUpRequest(formValues))
    .then(() => {
      // if no errores, continue...
      // Auto login on successful register...
      const { email, password } = formValues;
      dispatch(loginRequest(email, password))
        .then((response) => {
          // Auth function
          // Could split to helper if re-used elsewhere...
          // Set token to localStorage
          const { token } = response.payload.data;
          // Use the shared action to setToken
          dispatch(setToken(token));
          // localStorage.setItem('jwtToken', token);
          // // Set token to Auth header
          // authHelpers.setAuthToken(token);
          // // Decode token to get user data
          // const decoded = jwtDecode(token);
          // console.log(decoded);

          // // Set current user
          // dispatch(setCurrentUser(decoded));

          // history.push(PATH.CONNECT.url);
        })
        .catch(() => { });

      // history.push(PATH.CONNECT.url);
    })
    .catch(() => { });
};

const forgotPasswordRequest = (email) => (dispatch) => {
  const action = {
    types: [
      authTypes.FORGOT_PASSWORD_REQUEST,
      authTypes.FORGOT_PASSWORD_SUCCESS,
      authTypes.FORGOT_PASSWORD_FAIL,
    ],
    payload: {
      request: {
        url: `${API_URLS.users}/forgot-password`,
        method: 'post',
        data: {
          email: email.toLowerCase(),
        },
        isAuthRequired: false,
      },
    },
  };

  return dispatch(action);
};
const forgotPassword = (email) => (dispatch) => {
  dispatch(forgotPasswordRequest(email))
    .then(() => { })
    .catch(() => { });
};
const resetPasswordRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      authTypes.RESET_PASSWORD_REQUEST,
      authTypes.RESET_PASSWORD_SUCCESS,
      authTypes.RESET_PASSWORD_FAIL,
    ],
    payload: {
      request: {
        url: `${API_URLS.users}/reset-password/form/${formValues.token}`,
        method: 'post',
        data: {
          ...formValues,
        },
        isAuthRequired: false,
      },
    },
  };

  return dispatch(action);
};
export const resetPassword = (formValues) => (dispatch) => {
  dispatch(resetPasswordRequest(formValues))
    .then(() => { })
    .catch(() => { });
};

export const clearAuthStore = () => ({
  type: authTypes.RESET_AUTH,
});

// Log user out
const logOutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem('jwtToken');
  Cookies.remove('authoritzationJWT');
  // Remove auth header for future requests
  authHelpers.setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  dispatch(clearSettingsStore());
  dispatch(clearServicesStore());
  dispatch(clearAuthStore());
  // TODO: Clear the settings store as well, can we clear the entire Redux store??
  // dispatch(setSettings({}));...
  // dispatch(setPlatforms({}));
};

export default {
  signup,
  login,
  setToken,
  forgotPassword,
  logOutUser,
};
