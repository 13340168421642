import React, { useState, useEffect } from 'react';
import api from '_GhostContentAPI/GhostContentAPI';
import { useParams } from 'react-router-dom';
import history from '_utils/history';
import Header from '_components/_MarketingBlogPage/Header';
import Footer from '_components/_MarketingBlogPage/Footer';

import PostCard from '_components/_MarketingBlogPage/PostCard';
import Newsletter from '_components/_MarketingBlogPage/Newsletter/index';
import PopularPosts from '_components/_MarketingBlogPage/PopularPosts';

// Assets
import blogHeader from '_assets/assetsBlog/img/blog-header.jpg';
import MetaTags from '_components/MetaTags';

const Tag = () => {
  const { slug } = useParams();
  const [ghostTag, setGhostTag] = useState(undefined);
  const [postWithTag, setPostWithTag] = useState([]);
  const [ghostPosts, setghostPosts] = useState([]);

  const fetchTag = () => {
    api.tags
      .read({ slug }, { include: 'count.posts' })
      .then((tag) => {
        setGhostTag(tag);

        if (tag) {
          api.posts
            .browse({ filter: `tags:[${slug}]`, include: 'tags,authors' })
            .then((posts) => {
              setPostWithTag(posts);
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          history.push('/404');
        }
      })
      .catch((err) => {
        console.error(err);
        history.push('/404');
      });
    api.posts
      .browse({ include: 'tags,authors' })
      .then((posts) => setghostPosts(posts))
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchTag();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Hanlding not fouund page
  return (
    <>
      {ghostTag ? (
        <>
          <MetaTags title={`${ghostTag.name} Posts`} />
          <Header />
          <section
            className="has-divider text-light jarallax bg-dark"
            data-jarallax
            data-speed="0.5"
            data-overlay
          >
            <img src={blogHeader} alt="" className="jarallax-img opacity-50" />
            <div className="container">
              <div className="row">
                <div className="col-xl-5 col-lg-6 col-md-8">
                  <h1 className="display-4">{ghostTag.name}</h1>
                  <p className="lead mb-0">
                    {ghostTag.description
                      ? ghostTag.description
                      : `A collection of ${ghostTag.count.posts} posts`}
                  </p>
                </div>
              </div>
            </div>
            {/* <div class='divider'>
              <img src="{{asset "/img/blog-header.jpg/divider-2.svg"}}" alt="graphical divider" data-inject-svg />
            </div> */}
          </section>

          <section data-overlay>
            <div className="container">
              <div className="row mb-4">
                <div className="col-md-8 col-lg-9">
                  {postWithTag.map((post) => (
                    <PostCard post={post} key={post.id} />
                  ))}
                </div>
                <div className="col-md-4 col-lg-3 d-none d-md-block sticky-top">
                  <Newsletter />
                  <PopularPosts posts={ghostPosts} />
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      ) : null}
    </>
  );
};

export default Tag;
