import newServicesTypes from '_actionTypes/services.types';
import { API_URLS } from '_constants/api';
import { updateUser } from '_actions/mainUser.actions';

const addNewServiceRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      newServicesTypes.NEW_SERVICES_REQUEST,
      newServicesTypes.NEW_SERVICES_SUCCESS,
      newServicesTypes.NEW_SERVICES_FAIL,
    ],
    payload: {
      request: {
        url: `${API_URLS.users}/connect`,
        method: 'post',
        data: {
          ...formValues,
        },
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};

// TODO: not correctly refilling when done
export const addNewService = (formValues) => (dispatch) => {
  dispatch(addNewServiceRequest(formValues))
    .then((res) => dispatch(updateUser(res.payload.data.user)))
    .catch(() => { });
};
const updateTitleStreamsRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      newServicesTypes.UPDATE_TITLE_REQUEST,
      newServicesTypes.UPDATE_TITLE_SUCCESS,
      newServicesTypes.UPDATE_TITLE_FAIL,
    ],
    payload: {
      request: {
        url: `${API_URLS.users}/update/title`,
        method: 'post',
        data: {
          ...formValues,
        },
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};

// TODO: not correctly refilling when done
export const updateTitleStreams = (formValues) => (dispatch) => {
  dispatch(updateTitleStreamsRequest(formValues))
    .then((res) => dispatch(updateUser(res.payload.data.user)))
    .catch(() => { });
};
const updateRtmpRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      newServicesTypes.RTMP_REQUEST,
      newServicesTypes.RTMP_SUCCESS,
      newServicesTypes.RTMP_FAIL,
    ],
    payload: {
      request: {
        url: `${API_URLS.users}${formValues.typeUrl}`,
        method: 'post',
        data: {
          ...formValues,
        },
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};

export const updateRtmp = (formValues) => (dispatch) => {
  dispatch(updateRtmpRequest(formValues))
    .then((res) => dispatch(updateUser(res.payload.data.user)))
    .catch(() => { });
};
const deleteRtmpRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      newServicesTypes.RTMP_DELETE_REQUEST,
      newServicesTypes.RTMP_DELETE_SUCCESS,
      newServicesTypes.RTMP_DELETE_FAIL,
    ],
    payload: {
      request: {
        url: `${API_URLS.users}${formValues.typeUrl}`,
        method: 'post',
        data: {
          ...formValues,
        },
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};

export const deleteRtmp = (formValues) => (dispatch) => {
  dispatch(deleteRtmpRequest(formValues))
    .then((res) => dispatch(updateUser(res.payload.data.user)))
    .catch(() => { });
};

export const clearServicesStore = () => ({
  type: newServicesTypes.RESET_SERVICE,
});
