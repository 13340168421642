export const SUCCESS_SUFFIX = 'SUCCESS';
export const FAIL_SUFFIX = 'FAILURE';

export const APP_PRODUCTION_URL = '';
export const isProduction = process.env.NODE_ENV === 'production' && window && window.location.host === APP_PRODUCTION_URL;

// export const APP_API_URL = 'http://localhost:5000'; // TODO: this needs to be an environment varible for deployment
export const APP_API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000'; // Serve on the requested port or default to 5000

export const APP_COOKIES_DOMAIN = process.env.REACT_APP_COOKIES_DOMAIN || 'localhost'; // Serve on the requested port or default to 5000

// export const APP_API_URL = isProduction ? 'prod.api.url' : 'stage.api.url';
// const useLocalhost = true;
// export const baseURL = useLocalhost ? `${APP_API_URL}` : `${APP_API_URL}`;

export const API_URLS = {
  users: '/api/users',
  magicLink: '/api/magicLink',
  audio: '/api/audio-only-encode',
};

export const curbHost = process.env.NODE_ENV === 'production' ? 'curb-internal.streamon.dev' : 'localhost:5010';
