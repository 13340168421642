export default {
  SETTINGS_REQUEST: 'SETTINGS/SETTINGS_REQUEST',
  SETTINGS_SUCCESS: 'SETTINGS/SETTINGS_SUCCESS',
  SETTINGS_FAIL: 'SETTINGS/SETTINGS_FAIL',

  STREAMKEY_REQUEST: 'STREAMKEY/STREAMKEY_REQUEST',
  STREAMKEY_SUCCESS: 'STREAMKEY/STREAMKEY_SUCCESS',
  STREAMKEY_FAIL: 'STREAMKEY/STREAMKEY_FAIL',

  DARK_MODE_REQUEST: 'DARK_MODE/DARK_MODE_REQUEST',
  DARK_MODE_SUCCESS: 'DARK_MODE/DARK_MODE_SUCCESS',
  DARK_MODE_FAIL: 'DARK_MODE/DARK_MODE_FAIL',

  SETTINGS_PASSWORD_REQUEST: 'SETTINGS_PASSWORD/SETTINGS_PASSWORD_REQUEST',
  SETTINGS_PASSWORD_SUCCESS: 'SETTINGS_PASSWORD/SETTINGS_PASSWORD_SUCCESS',
  SETTINGS_PASSWORD_FAIL: 'SETTINGS_PASSWORD/SETTINGS_PASSWORD_FAIL',

  // Payment
  STRIPE_REQUEST: 'STRIPE/STRIPE_REQUEST',
  STRIPE_SUCCESS: 'STRIPE/STRIPE_SUCCESS',
  STRIPE_FAIL: 'STRIPE/STRIPE_FAIL',

  SUBSCRIPTION_REQUEST: 'SUBSCRIPTION/SUBSCRIPTION_REQUEST',
  SUBSCRIPTION_SUCCESS: 'SUBSCRIPTION/SUBSCRIPTION_SUCCESS',
  SUBSCRIPTION_FAIL: 'SUBSCRIPTION/SUBSCRIPTION_FAIL',

  CANCEL_SUBSCRIPTION_REQUEST: 'CANCEL_SUBSCRIPTION/CANCEL_SUBSCRIPTION_REQUEST',
  CANCEL_SUBSCRIPTION_SUCCESS: 'CANCEL_SUBSCRIPTION/CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_FAIL: 'CANCEL_SUBSCRIPTION/CANCEL_SUBSCRIPTION_FAIL',

  INFO_USER_REQUEST: 'INFO_USER/INFO_USER_REQUEST',
  INFO_USER_SUCCESS: 'INFO_USER/INFO_USER_SUCCESS',
  INFO_USER_FAIL: 'INFO_USER/INFO_USER_FAIL',

  RESET_SETTINGS: 'SETTINGS/SETTINGS_RESET',

  ERROR_SUBSCRIPTION: 'SUBSCRIPTION/SUBSCRIPTION_ERROR',
  SUCCESS_MESSAGE_SUBSCRIPTION: 'SUBSCRIPTION/SUBSCRIPTION_SUCCESS_MESSAGE',
  FAILURE_MESSAGE_SUBSCRIPTION: 'SUBSCRIPTION/SUBSCRIPTION_FAILURE_MESSAGE',

  USER_UPDATE: 'UPDATE/USER_UPDATE',
  STATUS_UPDATE: 'STATUS/USER_UPDATE_STATUS',
  TIME_UPDATE: 'TIME/USER_UPDATE_TIME',

  // DARK_MODE_UPDATE: 'DARK_MODE/USER_UPDATE_DARK_MODE',

  BILLING_SHOW: 'BILLING/CHANGE_BILLING_SHOW',
  STATE_CARD: 'UPDATE/CHANGE_STATE_CARD',

  UPLOAD_OVERLAY_REQUEST: 'UPLOAD_OVERLAY/UPLOAD_OVERLAY_REQUEST',
  UPLOAD_OVERLAY_SUCCESS: 'UPLOAD_OVERLAY/UPLOAD_OVERLAY_SUCCESS',
  UPLOAD_OVERLAY_FAIL: 'UPLOAD_OVERLAY/UPLOAD_OVERLAY_FAIL',

  SELECT_OVERLAY_REQUEST: 'SELECT_OVERLAY/SELECT_OVERLAY_REQUEST',
  SELECT_OVERLAY_SUCCESS: 'SELECT_OVERLAY/SELECT_OVERLAY_SUCCESS',
  SELECT_OVERLAY_FAIL: 'SELECT_OVERLAY/SELECT_OVERLAY_FAIL',

  DELETE_OVERLAY_REQUEST: 'DELETE_OVERLAY/DELETE_OVERLAY_REQUEST',
  DELETE_OVERLAY_SUCCESS: 'DELETE_OVERLAY/DELETE_OVERLAY_SUCCESS',
  DELETE_OVERLAY_FAIL: 'DELETE_OVERLAY/DELETE_OVERLAY_FAIL',

  SIGNED_URL_OVERLAY_REQUEST: 'SIGNED_URL_OVERLAY/SIGNED_URL_OVERLAY_REQUEST',
  SIGNED_URL_OVERLAY_SUCCESS: 'SIGNED_URL_OVERLAY/SIGNED_URL_OVERLAY_SUCCESS',
  SIGNED_URL_OVERLAY_FAIL: 'SIGNED_URL_OVERLAY/SIGNED_URL_OVERLAY_FAIL',
};
