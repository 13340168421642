import magicLinkType from '_actionTypes/magicLink.types';

const INITIAL_STATE = {
  loading: false,
  loadingPaypal: false,
  loadingShareToWatch: false,
  errors: {},
  message: '',
  messagePaypal: '',
};
const magicLinkReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case magicLinkType.RESET_MAGICKLINK:
      return INITIAL_STATE;
    case magicLinkType.MAGICLINK_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
      };
    case magicLinkType.MAGICLINK_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        message: action.payload.data.message,
      };
    case magicLinkType.MAGICLINK_FAIL: {
      // Log the errors into Redux here
      return {
        ...state,
        loading: false,
        errors: action.error.response.data ? action.error.response.data : { error: 'Undefined Error' },
      };
    }
    case magicLinkType.PAYPAL_SETTINGS_REQUEST:
      return {
        ...state,
        loadingPaypal: true,
        errors: {},
      };
    case magicLinkType.PAYPAL_SETTINGS_SUCCESS:
      return {
        ...state,
        loadingPaypal: false,
        errors: {},
        messagePaypal: action.payload.data.message,
      };
    case magicLinkType.PAYPAL_SETTINGS_FAIL: {
      // Log the errors into Redux here
      return {
        ...state,
        loadingPaypal: false,
        errors: action.error.response.data ? action.error.response.data : { error: 'Undefined Error' },
      };
    }
    case magicLinkType.SHARE_EMAIL_REQUEST:
      return {
        ...state,
        loadingShareToWatch: true,
        errors: {},
      };
    case magicLinkType.SHARE_EMAIL_SUCCESS:
      return {
        ...state,
        loadingShareToWatch: false,
        errors: {},
      };
    case magicLinkType.SHARE_EMAIL_FAIL: {
      // Log the errors into Redux here
      return {
        ...state,
        loadingShareToWatch: false,
        errors: action.error.response.data ? action.error.response.data : { error: 'Undefined Error' },
      };
    }
    case magicLinkType.IMPORT_CSV_REQUEST:
      return {
        ...state,
        errors: {},
      };
    case magicLinkType.IMPORT_CSV_SUCCESS:
      return {
        ...state,
        errors: {},
      };
    case magicLinkType.IMPORT_CSV_FAIL: {
      // Log the errors into Redux here
      return {
        ...state,
        errors: action.error.response.data ? action.error.response.data : { error: 'Undefined Error' },
      };
    }

    default:
      return state;
  }
};

export default magicLinkReducer;
