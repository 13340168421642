// import React from 'react';
// import { useTranslation } from 'react-i18next';
// import { Alert } from 'react-bootstrap';

const BetaAlert = () => {
  // const { t } = useTranslation();

  // Disable for now
  return null;

  // return (
  //   <Alert variant="primary">
  //     <strong>{t('betaalert.welcome')}</strong>
  //     {t('betaalert.message')}
  //     {' '}
  //     <a href="https://streamon.dev/help" target="_blank" rel="noopener noreferrer">
  //       Discord
  //     </a>
  //     . Ben + LLuis
  //   </Alert>
  // );
};

export default BetaAlert;
