import { combineReducers } from 'redux';
import authReducer from '_reducers/auth.reducer';
import errorReducer from '_reducers/error.reducer';
import uiState from '_reducers/mainUser.reducer';
import serviceReducer from '_reducers/services.reducer';
import magicLinkReducer from '_reducers/magicLink.reducer';
import audioReducer from '_reducers/audio.reducer';
import setUpActionsReducer from '_reducers/setUpAction.reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  errors: errorReducer,
  uiState,
  serviceReducer,
  magicLinkReducer,
  audioReducer,
  setUpActionsReducer,
});

export default rootReducer;
