import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '_assets/images/logo-white.svg';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="pb-5 bg-secondary text-light">
      <div className="container">
        <div className="row mb-4 justify-content-center">
          <div className="col-auto">
            <Link to="/">
              <img src={Logo} alt="StreamOn" className="icon icon-lg" />
            </Link>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <ul className="nav justify-content-center">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  {t('footer.home')}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  {t('footer.dashboard')}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/pricing" className="nav-link">
                  {t('footer.pricing')}
                </Link>
              </li>
              <li className="nav-item">
                <a href="https://roadmap.streamon.dev" className="nav-link">
                  {t('footer.roadmap')}
                </a>
              </li>
              <li className="nav-item">
                <Link to="/blog" className="nav-link">
                  {t('footer.blog')}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/page/help" className="nav-link">
                  {t('footer.help')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row justify-content-center mt-5 mb-5">
          <div className="col-auto">
            <ul className="nav">
              <li className="nav-item">
                <a className="nav-link" href="https://twitter.com/streamonapp">
                  <i className="fab fa-twitter fa-lg" />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://twitch.tv/streamonapp">
                  <i className="fab fa-twitch fa-lg" />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://facebook.com/streamonapp">
                  <i className="fab fa-facebook fa-lg" />
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.youtube.com/channel/UCVujJibryFy9u78d40JRJbQ?sub_confirmation=1"
                >
                  <i className="fab fa-youtube fa-lg" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row justify-content-center mt-4 pt-0">
          <div className="col-xl-4 col-lg-5 col-md-6">
            <div className="text-small text-center mb-4">
              <a href="https://streamon.dev/privacy-policy/" target="_blank"  rel="noopener noreferrer">
                {t('footer.privacyPolicy')}
              </a>
              {' '}
              |
              {' '}
              <a href="https://streamon.dev/terms-and-conditions/" target="_blank"  rel="noopener noreferrer">
                {t('footer.termsAndConditions')}
              </a>
            </div>
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-xl-10">
            <small className="text-muted">
              {t('footer.rights')}
              {' '}
              <a href="https://benbowler.limited" target="_blank"  rel="noopener noreferrer">
                Ben Bowler Limited
              </a>
            </small>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
