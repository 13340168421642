// import { GET_ERRORS } from "../_actionTypes/types";
import errorTypes from '_actionTypes/error.types';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case errorTypes.GET_ERRORS:
      return action.payload;
    default:
      return state;
  }
}
