import React from 'react';
import { Dropdown } from 'react-bootstrap';
import i18n from '../i18n';
import PropTypes from 'prop-types'

const SwitchLanguage = () => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={LinkToggle}>
        <i className="fa fa-language" aria-hidden="true" />
        {' '}
        {i18n.language === 'es'
          ? 'Español'
          : i18n.language === 'en'
            ? 'English'
            : i18n.language === 'kr'
              ? '한국어'
              : i18n.language === 'cn'
                ? '中文 (简体)'
                : 'English'}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item eventKey="1" onClick={() => changeLanguage('en')}>
          English &nbsp;
        </Dropdown.Item>
        <Dropdown.Item eventKey="2" onClick={() => changeLanguage('es')}>
          Español &nbsp;
        </Dropdown.Item>
        <Dropdown.Item eventKey="3" onClick={() => changeLanguage('cn')}>
          中文 (简体) &nbsp;
        </Dropdown.Item>
        <Dropdown.Item eventKey="4" onClick={() => changeLanguage('kr')}>
          한국어 &nbsp;
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const LinkToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href={() => false}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    {/* &#x25bc; */}
  </a>
));
LinkToggle.propTypes = {
  children: PropTypes.isRequired,
  onClick: PropTypes.isRequired,
}


export default SwitchLanguage;
