import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import ProfilePageLayout from '_components/Profile/PageLayout/ProfilePageLayout';
import Pages from '_pages';
import { Spinner, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import AuthPageLayout from '_components/Auth/PageLayout/AuthPageLayout';
import Button from '_components/shared/Button';
import Input from '_components/shared/Input';
import { resetPassword } from '_actions/auth.actions';
import * as Yup from 'yup';
import getUserCheck from '_selectors/auth.selectors';
import { APP_API_URL } from '_constants/api';
import trackEvent from '_utils/events';

const ResetPasswordPage = () => {
  const { token } = useParams();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [response, setResponse] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const checkPassword = useSelector(getUserCheck.getConfirmationPassword);

  const verifyPasswordToken = () => fetch(`${APP_API_URL}/api/users/reset-password/${token}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((el) => el.json())
    .then((el) => {
      console.log('eeeeeeeeeeeeeeeeeeeeeee', el);

      setResponse(el);
      setLoading(false);
    });
  useEffect(() => {
    trackEvent({ event: 'Page - Reset Password' });
    verifyPasswordToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schemaSetPassword = Yup.object().shape({
    password: Yup.string()
      .min(8)
      .required()
      .label('Password'),
    confirmPassword: Yup.string()
      .label('Confirm password')
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value;
      }),
  });

  const handleSubmit = (values) => {
    console.log('valuesvalueavlaes', values);

    dispatch(resetPassword(values));
  };

  return (
    <div>
      {loading ? (
        <ProfilePageLayout>
          <div className="text-center mx-5">
            <Spinner animation="border" role="status">
              <span className="sr-only">{t('filepreview.loading')}</span>
            </Spinner>
          </div>
        </ProfilePageLayout>
      ) : (
          <>
            {response && response.check === true ? (
              <>
                {checkPassword && checkPassword.checkPassword === true ? (
                  <Redirect to="/login" />
                ) : (
                    <AuthPageLayout
                      title={t('forgotPassword.title')}
                      subtitle={t('forgotPassword.reset')}
                    >
                      <Formik
                        initialValues={{
                          password: '',
                          confirmPassword: '',
                          token,
                        }}
                        validationSchema={schemaSetPassword}
                        onSubmit={async (values, { resetForm }) => {
                          await handleSubmit(values);
                          resetForm();
                        }}
                      >
                        {({
                          handleChange, handleSubmit, values, resetForm, errors, touched,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                              <div className="input-group-lg ">
                                {/* <h5 className='mb-1 mt-3'>Set Password</h5> */}
                                <Input
                                  id="password"
                                  name="password"
                                  type="password"
                                  isInvalid={touched && errors.password}
                                  error={errors.password}
                                  value={values.password}
                                  onChange={handleChange}
                                  wrapperClassName="input-group-lg"
                                  inputClassName="copy-input"
                                  formikKey="password"
                                  placeholder=" New Password"
                                  secureTextEntry
                                />

                                <Input
                                  id="confirmPassword"
                                  name="confirmPassword"
                                  type="password"
                                  isInvalid={touched && errors.confirmPassword}
                                  error={errors.confirmPassword}
                                  value={values.confirmPassword}
                                  onChange={handleChange}
                                  wrapperClassName="input-group-lg"
                                  inputClassName="copy-input"
                                  formikKey="confirmPassword"
                                  placeholder="Confirm Password"
                                  secureTextEntry
                                />

                                <div className="d-flex flex-row-reverse mt-3">
                                  <Button
                                    variant="secondary"
                                    className="mb-2 mx-1"
                                    toUppercase
                                    text={t('Cancel')}
                                    onClick={() => resetForm()}
                                  />
                                  <Button
                                    variant="primary"
                                    className="btn-outline-secondary mb-2 mx-1"
                                    toUppercase
                                    type="submit"
                                    text={t('Apply')}
                                  />
                                </div>
                              </div>
                            </Form>
                          )}
                      </Formik>
                    </AuthPageLayout>
                  )}
              </>
            ) : (
                <Pages.NotFoundPage error={t('notFound.errorpassword')} />
              )}
          </>
        )}
    </div>
  );
};

export default ResetPasswordPage;
