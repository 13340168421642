import React, { useEffect } from 'react';
import { months } from '../../../_utils/globalData';
import Aos from 'aos';

const PreviewPosts = ({ ghostPosts }) => {
  console.log('PreviewPosts -> ghostPosts', ghostPosts);
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      {ghostPosts.map(post => {
        let formattedDate =
          new Date(post.published_at).getDate() +
          ' ' +
          months[new Date(post.published_at).getMonth()] +
          ' ' +
          new Date(post.published_at).getFullYear();
        console.log('Blog -> formattedDate', formattedDate);
        if (post.feature_image) {
          return (
            <div class='col-md-6 col-lg-4 d-flex' data-aos='fade-up' data-aos-delay='200'>
              <div class='card'>
                <a href={post.url}>
                  <img src={post.feature_image} alt=' Feature' class='card-img-top' />
                </a>
                <div class='card-body d-flex flex-column'>
                  <div class='d-flex justify-content-between mb-3'>
                    <div class='text-small d-flex'>
                      {post?.primary_tag && (
                        <a href={post.primary_tag.url}>{post.primary_tag.name} &nbsp;</a>
                      )}
                      <span class='text-muted'>
                        <time dateTime={post.published_at.split('T')[0]}>{formattedDate}</time>
                      </span>
                    </div>
                  </div>
                  <a href={post.url}>
                    <h4>{post.title}</h4>
                  </a>
                  <p class='flex-grow-1'>{post.excerpt}</p>

                  <div class='d-flex align-items-center mt-3'>
                    <img
                      src={post.authors[0].profile_image}
                      alt={post.authors[0].name}
                      class='avatar avatar-sm'
                    />
                    <div class='ml-1'>
                      <span class='text-small text-muted'>By&nbsp;</span>
                      <span class='text-small'>{post.authors[0].name}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div class='col-md-6 col-lg-4 d-flex' data-aos='fade-up' data-aos-delay='100'>
              <a
                href={post.url}
                class='card card-body justify-content-between bg-primary text-light'
              >
                <div class='d-flex justify-content-between mb-3'>
                  <div class='text-small d-flex'>
                    {post?.primary_tag && (
                      <a href={post.primary_tag.url}>{post.primary_tag.name}</a>
                    )}
                    <span class='text-muted'>
                      <time dateTime={post.published_at.split('T')[0]}>{formattedDate}</time>
                    </span>
                  </div>
                </div>
                <h2>{post.title}</h2>

                <p class='flex-grow-1'>{post.excerpt}</p>
                <div class='d-flex align-items-center mt-3'>
                  <img
                    src={post.authors[0].profile_image}
                    alt={post.authors[0].name}
                    class='avatar avatar-sm'
                  />
                  <div class='ml-1'>
                    <span class='text-small text-muted'>By&nbsp;</span>
                    <span class='text-small'>{post.authors[0].name}</span>
                  </div>
                </div>
              </a>
            </div>
          );
        }
      })}
    </>
  );
};

export default PreviewPosts;
