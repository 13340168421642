const messageMagickLink = (state) => state.magicLinkReducer.message;

const loadingPaypal = (state) => state.magicLinkReducer.loadingPaypal;
const loadingShareToWatch = (state) => state.magicLinkReducer.loadingShareToWatch;

export default {
  messageMagickLink,
  loadingPaypal,
  loadingShareToWatch,
};
