export default {
  MAGICLINK_REQUEST: 'MAGICLINK/MAGICLINK_REQUEST',
  MAGICLINK_SUCCESS: 'MAGICLINK/MAGICLINK_SUCCESS',
  MAGICLINK_FAIL: 'MAGICLINK/MAGICLINK_FAIL',

  PAYPAL_SETTINGS_REQUEST: 'PAYPAL_SETTINGS/PAYPAL_SETTINGS_REQUEST',
  PAYPAL_SETTINGS_SUCCESS: 'PAYPAL_SETTINGS/PAYPAL_SETTINGS_SUCCESS',
  PAYPAL_SETTINGS_FAIL: 'PAYPAL_SETTINGS/PAYPAL_SETTINGS_FAIL',

  SHARE_EMAIL_REQUEST: 'SHARE_EMAIL/SHARE_EMAIL_REQUEST',
  SHARE_EMAIL_SUCCESS: 'SHARE_EMAIL/SHARE_EMAIL_SUCCESS',
  SHARE_EMAIL_FAIL: 'SHARE_EMAIL/SHARE_EMAIL_FAIL',

  IMPORT_CSV_REQUEST: 'IMPORT_CSV/IMPORT_CSV_REQUEST',
  IMPORT_CSV_SUCCESS: 'IMPORT_CSV/IMPORT_CSV_SUCCESS',
  IMPORT_CSV_FAIL: 'IMPORT_CSV/IMPORT_CSV_FAIL',

  SET_MAGICLINK: 'SET_MAGICLINK/SET_INITIAL_MAGICLINK',
  RESET_MAGICKLINK: 'MAGICKLINK/RESET_MAGICKLINK',
};
