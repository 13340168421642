import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Modal, Button, Spinner, Form,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import magickLinkSelector from '_selectors/magickLink.selector';
import Input from '_components/shared/Input';
import { shareToWatch } from '_actions/magicLink.actions';
import Cookies from 'js-cookie';
import { APP_COOKIES_DOMAIN } from '_constants/api';

const ShareToWatchModal = ({ user }) => {
  console.log('ShareToWatchModal -> user', user);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [show, setShow] = useState(true);
  const loadingShareToWatch = useSelector(magickLinkSelector.loadingShareToWatch);

  const schemaUser = Yup.object().shape({
    email: Yup.string()
      .email()
      .max(128)
      .required()
      .label('Email'),
    name: Yup.string()
      .max(64)
      .label('Name'),
  });

  const handleSubmit = async (values) => {
    await dispatch(
      shareToWatch({
        email: values.email,
        name: values.name,
        magicLink: user.magicLink,
        url: '/shareToWatch/subscribe',
      }),
    );
    Cookies.set(`${user.magicLink}-subscribe`, `${user.magicLink}-subscribe`, {
      // expires: 60, //Days in which it will expiry
      domain: APP_COOKIES_DOMAIN,
    });
    setShow(!show);
  };

  return (
    <Modal show={show} onHide={() => setShow(!show)}>
      <Modal.Header closeButton>
        <Modal.Title>{t('engage.magicLink.emailToWatch')}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          id: user._id,
          email: undefined,
          name: undefined,
        }}
        validationSchema={schemaUser}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          values, handleSubmit, handleChange, errors, touched,
        }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <h5>{user.shareToWatch.customMessage}</h5>
                <Input
                  id="email"
                  name="email"
                  isInvalid={touched && errors.email}
                  error={errors.email}
                  label={t('fields.email')}
                  value={values.email}
                  placeholder="example@gmail.com"
                  onChange={handleChange}
                />
                <Input
                  id="name"
                  name="name"
                  isInvalid={touched && errors.name}
                  error={errors.name}
                  label={t('fields.name')}
                  value={values.name}
                  placeholder={t('fields.optional')}
                  onChange={handleChange}
                />
              </Modal.Body>
              <Modal.Footer>
                {/* <Button variant='secondary' onClick={() => setShow(!show)}>
                {t('elements.button.close')}
              </Button> */}
                <Button variant="primary" type="submit">
                  {!loadingShareToWatch ? (
                    t('elements.button.confirm')
                  ) : (
                      <Spinner animation="border" role="status" size="sm" />
                    )}
                </Button>
              </Modal.Footer>
            </Form>
          )}
      </Formik>
    </Modal>
  );
};

export default ShareToWatchModal;
