const getServices = (state) => state?.uiState?.mainUser?.services;
const getAccounts = (state) => state.uiState.mainUser.accounts;

const getUserInfo = (state) => state.uiState.mainUser;

const getUserErrors = (state) => state.uiState.errors;

// Payment
const getShowBilling = (state) => state.uiState.billing;

const getErrorsSubscription = (state) => state.uiState.subscriptionErrors;

const getSuccessSubscription = (state) => state.uiState.subscriptionSuccess;

const subscriptionLoading = (state) => state.uiState.loadingSubscriptionrequest;

const loadingCancel = (state) => state.uiState.loadingCancelrequest;

const getUpdateCard = (state) => state.uiState.updateCard;

export default {
  getUpdateCard,
  getUserInfo,
  getUserErrors,
  getServices,
  getAccounts,
  getErrorsSubscription,
  getSuccessSubscription,
  subscriptionLoading,
  loadingCancel,
  getShowBilling,
};
