/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Form, Spinner } from 'react-bootstrap';
import Input from '_components/shared/Input';
import { useSelector, useDispatch } from 'react-redux';
import userSelectors from '_selectors/mainUser.selectors';
import {
  stripeRegister,
  stripeSubscription,
  refreshErrorSubscription,
  setUpdateCard,
} from '_actions/mainUser.actions';
import { useTranslation } from 'react-i18next';

const CheckOutForm = ({ priceId, uri }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();

  const [coupon, setCoupon] = useState('');
  const [zip, setZip] = useState('');
  // const user = useSelector(settingsSelectors.getUserInfo);
  const user = useSelector(userSelectors.getUserInfo); // New format
  const getUpdateCard = useSelector(userSelectors.getUpdateCard);

  const buttonLoading = useSelector(userSelectors.subscriptionLoading);

  useEffect(() => {
    dispatch(refreshErrorSubscription());
    if (user.stripeCustomerId === undefined && user.email) {
      dispatch(stripeRegister(user.email));
    }
  }, []);
  // Needs passing down to the server to save the card
  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement.
    const cardElement = elements.getElement(CardElement);
    // Use your card Element with other Stripe.js APIs

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
    } else {
      // If the user hasn't got a subscription => create one
      if (getUpdateCard === true && user.subscriptionId !== undefined) {
        console.log('fooooooooooooooooooooooo');
        dispatch(setUpdateCard(false));

        dispatch(
          stripeSubscription({
            customerId: user.stripeCustomerId,
            subscriptionId: user.subscriptionId,
            paymentMethodId: paymentMethod.id,
            zip,
            url: 'update-card',
            coupon,
          }),
        );
      } else if (user.subscriptionId === undefined) {
        console.log('[PaymentMethod]', paymentMethod);
        dispatch(
          stripeSubscription({
            uri,
            customerId: user.stripeCustomerId,
            paymentMethodId: paymentMethod.id,
            zip,
            priceId,
            coupon,
            url: 'create-subscription',
          }),
        );
      } else {
        // If the user has a subscription update it

        dispatch(
          stripeSubscription({
            uri,
            customerId: user.stripeCustomerId,
            subscriptionId: user.subscriptionId,
            zip,
            priceId,
            url: 'update-subscription',
          }),
        );
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-control py-0">
        <CardElement className="py-2 mb-2" options={{ hidePostalCode: true }} />
      </div>
      <div className="pt-2 mt-2 d-flex align-items-end flex-column">
        <div className="d-flex">
          <label className="mt-1">
            {' '}
            <strong>
              {t('checkoutform.zip')}
              : &nbsp;
            </strong>
          </label>
          <Input
            id="Zip"
            name="Zip"
            type="text"
            placeholder="e.g. - 46842"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
          />
        </div>
        <div className="d-flex">
          <label className="mt-1">
            {' '}
            <strong>
              {t('checkoutform.coupon')}
              : &nbsp;
            </strong>
          </label>
          <Input
            id="Coupon"
            name="Coupon"
            type="text"
            placeholder="e.g. - 2Zt4lWOc"
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
          />
        </div>
      </div>
      <div className="d-flex">
        <Button type="submit" disabled={!stripe}>
          {buttonLoading ? (
            <Spinner animation="border" size="sm" role="status" className="m-0" />
          ) : (
              <>
                {user.subscriptionId ? t('elements.button.update') : t('elements.button.subscribe')}
              </>
            )}
        </Button>
      </div>
    </Form>
  );
};

export default CheckOutForm;
