import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

// import { infoUser, updateDarkMode } from '_actions/mainUser.actions';
import { darkModeAction } from '_actions/mainUser.actions';
import mainUserSelectors from '_selectors/mainUser.selectors';
import { useSelector, useDispatch } from 'react-redux';

const DarkModeSwitch = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector(mainUserSelectors.getUserInfo);

  // TODO: get the current setting from the user...
  const [darkMode, changeMode] = useState(user.darkMode ? user.darkMode : false);
  const storeMode = () => {
    // console.log(e);
    // Stop the menu closing
    // e.stopPropagation(); // This doesn't work - The bootstrap menu still closes

    // Update the state and the store
    changeMode(!darkMode);
    dispatch(darkModeAction({ id: user._id }));
  };

  return (
    <Form.Check
      type="switch"
      id="dark-mode-switch"
      value={!darkMode}
      label={darkMode ? t('darkMode.dark') : t('darkMode.light')}
      onChange={storeMode}
    />
  );
};

export default DarkModeSwitch;
