import React, { useState, useEffect } from 'react';
import Integrations from '_constants/Integrations.json';
import { useParams } from 'react-router-dom';
import { availableServices } from '_utils/globalData';
import history from '_utils/history';
import Header from '_components/_MarketingBlogPage/Header';
import Footer from '_components/_MarketingBlogPage/Footer';
import PreviewPosts from '_components/_MarketingBlogPage/PreviewPosts';
import api from '_GhostContentAPI/GhostContentAPI';
import MetaTags from '_components/MetaTags';

const ServiceIntegrationPage = () => {
  const { slug, slug1 } = useParams();

  const [imageError, setImageError] = useState(false);

  const [service] = useState(
    [...availableServices].filter(el => el.formatType === slug)[0]
  );
  const [integration] = useState(Integrations.filter(el => el.id === slug1)[0]);
  const [ghostPosts, setghostPosts] = useState([]);
  const fetchPosts = () => {
    api.posts
      .browse({ limit: 3, include: 'tags,authors' })
      .then(posts => setghostPosts(posts))
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchPosts();
    if (service === undefined) {
      history.push('/404');
    }
    if (integration === undefined) {
      history.push('/404');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const capitalize = s => s[0].toUpperCase() + s.slice(1);

  return (
    <>
      <MetaTags
        title={`${capitalize(service.type)} with ${integration.name}`}
        image={`https://streamon-public-assets.s3.eu-west-1.amazonaws.com/thumbnails/service-${slug}-with-${slug1}.png`}
        description={integration?.description}
      />
      <Header />
      <section className='bg-primary-alt header-inner'>
        <div className='container'>
          <div className='row my-3'>
            <div className='col'>
              <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <a href='index.html'>Home</a>
                  </li>
                  {/* TODO: Services listing page, like the directory page */}
                  <li className='breadcrumb-item'>
                    <a href={() => false}>Services</a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className='row justify-content-center my-4'>
            <div className='col-auto'>
              {imageError ? (
                <i className='fas fa-server fa-3x' />
              ) : (
                  <img
                    src={service.iconStyle}
                    onError={() => setImageError(true)}
                    className='icon icon-lg'
                    alt={`Live Stream to ${service.type} with StreamOn`}
                  />
                )}
            </div>
          </div>
          <div className='row justify-content-center text-center'>
            <div className='col-lg-9 col-xl-8'>
              {/* TODO: featured service badge if featured */}
              <h1>
                {integration?.name} with {capitalize(service.type)} - Try StreamOn
              </h1>
            </div>
          </div>
          <div className='row my-6 justify-content-between'>
            <div className='col-lg-7 col-xl-8 mb-3 mb-lg-0'>
              {/* TODO: use the auto generated images from the next Trello task */}
              <img
                src={`https://streamon-public-assets.s3.eu-west-1.amazonaws.com/thumbnails/service-${slug}-with-${slug1}.png`}
                alt='Integration Service'
                className='rounded'
              />
            </div>
            <div className='col-lg-4 col-xl-3'>
              <div className='mb-3'>
                <h6 className='mb-1'>Description</h6>
                <p>{service.description}</p>
              </div>
              {service.websiteUrl && (
                <div className='mb-3'>
                  <h6 className='mb-1'>Website</h6>
                  <a href={service.websiteUrl}>{service.websiteUrl}</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className='p-0' data-reading-position>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-7 col-lg-8 col-md-10'>
              <article className='article'>
                <p className='lead'>{integration?.description}</p>
                {/* <p>{integration.description}</p> */}
                <strong>
                  Using StreamOn you can use {capitalize(service.type)} with more great features:
                </strong>
                <ul className='mt-2'>
                  {/* TODO: take 5 random integrations.. */}
                  <li>Unlimited live stream forwarding with {capitalize(service.type)}</li>
                  <li>Commssion free tipping with {capitalize(service.type)}</li>
                  <li>Email subscriptions with {capitalize(service.type)}</li>
                </ul>
              </article>
            </div>
          </div>
        </div>
      </section>
      {/* Other Services Section */}
      <section className='p-0 my-6' data-reading-position>
        <div className='container'>
          <div className='row text-center w-100'>
            <div className='col layer-2 w-100'>
              <h2 className='h1'>Other Live Streaming Services</h2>
              <div className='lead mb-4'>Try some of the 60+ services supported by StreamOn</div>

              {/* <Link to='/'>
                <button className='btn btn-primary'>{t('directory.button')}</button>
              </Link> */}
            </div>
          </div>
          <div className='row justify-content-center mb-md-3 mt-3'>
            {[...availableServices]
              .sort(() => 0.5 - Math.random())
              .slice(0, 12)
              .map((randomServices, i) => {
                // console.log('ServicesPage -> randomServices', randomServices);
                if (slug !== randomServices.formatType) {
                  return (
                    <a
                      key={i}
                      className='mt-1 mx-auto w-25'
                      href={`/service/${randomServices.formatType}/with/${slug1}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {randomServices.formatType === 'rtmpserver' ||
                        randomServices.formatType === 'taryana-apachat|تاریانا-آپاچت' ? (
                          <i className='fas fa-server fa-3x' />
                        ) : (
                          <img
                            src={randomServices.iconStyle}
                            className='icon icon-lg'
                            alt={`Live Stream to ${randomServices.type} with StreamOn`}
                            onError={e => (e.target.style.display = 'none')}
                          />
                        )}

                      <span className='mx-3 truncate'>
                        {capitalize(capitalize(randomServices.type))}
                      </span>
                    </a>
                  );
                }
                return null;
              })}
          </div>
        </div>
      </section>
      <section class='bg-primary-3 text-light o-hidden'>
        <div class='container layer-1'>
          <div class='row justify-content-center text-center mb-3'>
            <div class='col-xl-8 col-lg-9'>
              <h2 class='display-4 mx-xl-6'>
                Stream to {capitalize(service.type)} and 60+ services
              </h2>
              <h4>Sign up for a StreamOn account for FREE unlimited re-streaming</h4>
            </div>
          </div>
          <div class='row justify-content-center'>
            <a href='https://streamon.app/signup' class='btn btn-primary-2 btn-lg ml-lg-3'>
              Join BETA 4
            </a>
          </div>
        </div>
      </section>
      <section className='bg-primary-alt'>
        <div className='container'>
          <div className='row mb-4'>
            <h3 className='h2'>Related Posts from the Blog</h3>
          </div>
          <div className='d-flex'>
            <PreviewPosts ghostPosts={ghostPosts} />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ServiceIntegrationPage;
