import React, { useEffect } from 'react';
import { node } from 'prop-types';
import Header from '_components/Header';
import Breadcrumbs from '_components/Breadcrumbs';
import Footer from '_components/Footer';
import { useSelector, useDispatch } from 'react-redux';
import mainUserSelectors from '_selectors/mainUser.selectors';
import { darkModeAction } from '_actions/mainUser.actions';

const PageLayout = ({ children }) => {
  const dispatch = useDispatch();

  // Get the darkMode status
  const user = useSelector(mainUserSelectors.getUserInfo);

  // Set the default theme if the user doesn't already have one
  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches &&
      user?.darkMode === undefined
    ) {
      // Set dark mode based on user settings
      dispatch(darkModeAction({ id: user._id }));
    }
  });

  return (
    <div className={user?.darkMode && 'dark-mode'}>
      <Header />
      <Breadcrumbs />
      {children}
      <Footer />
    </div>
  );
};

PageLayout.propTypes = {
  children: node.isRequired,
};

export default PageLayout;
