import React from 'react';
import { Link } from 'react-router-dom';
import useRouter from '_hooks/useRouter';
import { PATH } from '_routes/Routes';
import { isObject } from 'lodash';
import { useTranslation } from 'react-i18next';

const Breadcrumbs = () => {
  const { pathname } = useRouter();
  const { t } = useTranslation();

  const pathfirstSlash = `/${pathname.split('/')[1]}`;

  const activeBreadcrumb = Object.values(PATH).find((currentPath) => {
    if (isObject(currentPath)) {
      return currentPath.url === pathfirstSlash;
    }

    return null;
  });

  return (
    <section className="pt-5 pb-3">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-xl-10">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">{t('elements.home')}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={pathname}>{activeBreadcrumb ? activeBreadcrumb.name : null}</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Breadcrumbs;
