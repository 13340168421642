import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ProfilePageLayout from '_components/Profile/PageLayout/ProfilePageLayout';
import ShareToWatchModal from '_components/ShareToWatchModal/index';
import authServices from '_utils/authServices';
import { useParams } from 'react-router-dom';
import Pages from '_pages';
import { Modal, Button, Spinner, Form } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { APP_API_URL, APP_COOKIES_DOMAIN } from '_constants/api';
import MetaTags from '_components/MetaTags';
import trackEvent from '_utils/events';

const ProfilePage = () => {
  const { t } = useTranslation();
  const { slug } = useParams();

  const [userProfile, setUserProfile] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const fetchUserProfile = data => {
    return fetch(`${APP_API_URL}/api/magicLink/profile/${data}`, {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(el => el.json())
      .then(res => {
        setUserProfile(res.userProfile);
        setLoading(false);
      });
  };

  useEffect(() => {
    //If user set a cookie, if not fecth it
    if (userProfile) {
      trackEvent({
        event: 'Page - Profile',
        properties: {
          whom: userProfile?._id,
          email: userProfile?.email,
        },
      });
      //If it comes from a successful donation set a cookie
      if (window.location.href.split('?')[1] === 'paypal=success') {
        console.log('aaaaaaaaaaaaaaaaaaaaaaa', userProfile.magicLink);

        Cookies.set(userProfile.magicLink, userProfile.magicLink, {
          expires: 60, //Days in which it will expiry
          domain: APP_COOKIES_DOMAIN,
        });
      }
    } else {
      fetchUserProfile(slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUserProfile, userProfile]);

  const handleClose = () => {
    setShow(!show);
  };

  return (
    <>
      {/* TODO: style the loader to look like the profile without information */}
      {loading ? (
        <ProfilePageLayout>
          <div className='text-center mx-5'>
            <Spinner animation='border' role='status'>
              <span className='sr-only'>{t('filepreview.loading')}</span>
            </Spinner>
          </div>
        </ProfilePageLayout>
      ) : (
          <>
            {userProfile ? (
              <>
                <MetaTags
                  title={userProfile.name}
                  image={userProfile.profilePicture}
                  description={`Watch ${userProfile.name}'s live streams, subscribe and tip all in one place.'.`}
                />
                <ProfilePageLayout>
                  {Cookies.get(userProfile.magicLink) === slug ? null : (
                    <Modal
                      show={show && userProfile?.settingsPaypal?.paypalEnabled}
                      onHide={() => setShow(!show)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{t('paypal.title')}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>{userProfile?.settingsPaypal?.customMessage}</Modal.Body>
                      <Modal.Footer>
                        <Button variant='link' onClick={() => handleClose()}>
                          {t('elements.button.notDonate')}
                        </Button>
                        {/* <Form
                      action='https://www.paypal.com/cgi-bin/webscr'
                      method='post'
                      target='_top'
                    >
                      <input type='hidden' name='cmd' value='_donations' />

                      <input
                        type='hidden'
                        name='business'
                        value={userProfile.settingsPaypal.paypalEmail}
                      />
                      <input
                        type='hidden'
                        name='item_name'
                        value={`Donation to: ${userProfile.name}`}
                      />
                      <input
                        type='hidden'
                        name='currency_code'
                        value={userProfile.settingsPaypal.currencyCode}
                      />
                      <input
                        type='hidden'
                        name='return'
                        value={`http://localhost:8085/${userProfile.magicLink}?paypal=success`}
                      />
                      <input
                        type='image'
                        src='https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif'
                        border='0'
                        name='submit'
                        title='PayPal - The safer, easier way to pay online!'
                        alt='Donate with PayPal button'
                      />
                      <img
                        alt=''
                        border='0'
                        src='https://www.paypal.com/en_GB/i/scr/pixel.gif'
                        width='1'
                        height='1'
                      />
                    </Form> */}
                        {/* Testing Paypal Donate Button, there will need credentails to test it */}
                        <Form
                          action='https://www.sandbox.paypal.com/cgi-bin/webscr'
                          method='post'
                          target='_top'
                        >
                          <input type='hidden' name='cmd' value='_s-xclick' />
                          <input type='hidden' name='hosted_button_id' value='B2VUWE2ZW2XUL' />
                          <input
                            type='hidden'
                            name='return'
                            value={`http://localhost:8085/${userProfile.magicLink}?paypal=success`}
                          />
                          <input
                            type='image'
                            src='https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif'
                            border='0'
                            name='submit'
                            title='PayPal - The safer, easier way to pay online!'
                            alt='Donate with PayPal button'
                          />
                          <img
                            alt=''
                            border='0'
                            src='https://www.sandbox.paypal.com/en_GB/i/scr/pixel.gif'
                            width='1'
                            height='1'
                          />
                        </Form>
                      </Modal.Footer>
                    </Modal>
                  )}
                  {!Cookies.get(userProfile.magicLink + '-subscribe') &&
                    userProfile?.shareToWatch?.enabled === true && (
                      <ShareToWatchModal user={userProfile} />
                    )}
                  <div className='card card-profile mt-6'>
                    {userProfile.backgroundPicture ? (
                      <img
                        className='card-img-top'
                        src={userProfile.backgroundPicture}
                        alt={userProfile.name + ' Profile Picture'}
                      />
                    ) : null}
                    <div className='card-body text-center '>
                      {userProfile.profilePicture ? (
                        <img
                          className='avatar avatar-lg rounded-circle'
                          src={userProfile.profilePicture}
                          alt={userProfile.name + ' Profile Background'}
                        />
                      ) : null}
                      <h4 className='card-title'>{userProfile.name}</h4>
                      <h6 className='card-subtitle mb-3 text-muted'>
                        {userProfile.description
                          ? userProfile.description
                          : 'This is the bio I set on the Engage page..'}
                      </h6>
                      <div className='card-text'>
                        {/* TODO: Ben: track the preference in a cookie and redirect */}
                        {userProfile.services.map(service => {
                          return authServices.map(serviceButton => {
                            if (service.active) {
                              if (serviceButton.provider === service.type) {
                                console.log(serviceButton.provider);

                                if (service.type === 'facebook') {
                                  if (service.pageId !== undefined) {
                                    return (
                                      // Page stream link
                                      <a
                                        key={service._id}
                                        className={serviceButton.className}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        href={
                                          service.streamId !== undefined
                                            ? `https://www.facebook.com/${service.pageId}/videos/${service.streamId}/`
                                            : `https://www.facebook.com/${service.pageId}/`
                                        }
                                      >
                                        {serviceButton.icon}
                                        {t('profile.watch')} {service.displayName || service.title}
                                      </a>
                                    );
                                  } else if (service.groupId !== undefined) {
                                    return (
                                      // Group page link
                                      <a
                                        key={service._id}
                                        className={serviceButton.className}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        href={
                                          service.streamId !== undefined
                                            ? `https://www.facebook.com/${service.groupId}/videos/${service.streamId}/`
                                            : `https://www.facebook.com/${service.groupId}/`
                                        }
                                      >
                                        {serviceButton.icon}
                                        {t('profile.watch')} {service.displayName || service.title}
                                      </a>
                                    );
                                  } else {
                                    console.log('userProfile.profileFacebook', userProfile);
                                    return (
                                      // Personal profile link
                                      <a
                                        key={service._id}
                                        className={serviceButton.className}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        href={service.profileFacebook}
                                      >
                                        {serviceButton.icon}
                                        {t('profile.watch')} {service.displayName || service.title}
                                      </a>
                                    );
                                  }
                                }
                                if (service.type === 'youtube') {
                                  return (
                                    <a
                                      key={service._id}
                                      className={serviceButton.className}
                                      //google url, we need to pass the right id
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      href={
                                        service.broadcastId
                                          ? `https://www.youtube.com/watch?v=${service.broadcastId}`
                                          : `https://www.youtube.com/channel/${service.youtubeChannelId}`
                                      }
                                    >
                                      {serviceButton.icon}
                                      {t('profile.watch')} {service.displayName || service.title}
                                    </a>
                                  );
                                }
                                if (service.type === 'twitch') {
                                  return (
                                    // It is needed to put the 'to' - property with the Sream link url
                                    <a
                                      key={service._id}
                                      className={serviceButton.className}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      href={`https://twitch.tv/${service.displayName}`}
                                    >
                                      {serviceButton.icon}
                                      {t('profile.watch')} {service.displayName || service.title}
                                    </a>
                                  );
                                }
                              }
                            }
                            return null;
                          });

                        })}
                      </div>
                    </div>
                  </div>
                </ProfilePageLayout>
              </>
            ) : (
                <Pages.NotFoundPage />
              )}
          </>
        )}
    </>
  );
};

export default ProfilePage;
