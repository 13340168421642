import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { availableServices } from '_utils/globalData';
import history from '_utils/history';
import Header from '_components/_MarketingBlogPage/Header';
import Footer from '_components/_MarketingBlogPage/Footer';
import PreviewPosts from '_components/_MarketingBlogPage/PreviewPosts';
import api from '_GhostContentAPI/GhostContentAPI';

import MetaTags from '_components/MetaTags';

const ServiceCombinationsPage = () => {
  const { slug, slug1 } = useParams();

  const [imageError, setImageError] = useState(false);
  const [imageError2, setImageError2] = useState(false);

  const [service] = useState(
    [...availableServices].filter((el) => el.formatType === slug)[0],
  );
  const [secondService] = useState(
    [...availableServices].filter((el) => el.formatType === slug1)[0],
  );
  const [ghostPosts, setghostPosts] = useState([]);
  const fetchPosts = () => {
    api.posts
      .browse({ limit: 3, include: 'tags,authors' })
      .then((posts) => setghostPosts(posts))
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchPosts();
    if (service === undefined) {
      history.push('/404');
    }
    if (secondService === undefined) {
      history.push('/404');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const capitalize = (s) => s[0].toUpperCase() + s.slice(1);
  // Get random logos
  const arrayToLogo = [...availableServices]
    .sort(() => 0.5 - Math.random())
    .slice(0, 24);
  // Modify the arrayy to be able to loop trough it and render as [[2],[2],[2]...]
  const matrix = arrayToLogo.reduce((rows, key, index) => (index % 2 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows, []);

  return (
    <>
      <MetaTags
        title={`Live Stream to ${capitalize(service.type)} and ${capitalize(secondService.type)}`}
        image={`https://streamon-public-assets.s3.eu-west-1.amazonaws.com/thumbnails/service-${slug}-and-${slug1}.png`}
        description={`Go live to ${capitalize(capitalize(service.type))} and
        ${capitalize(secondService.type)} using StreamOn. Increase the quality of your
        streams and reduce the strain on your CPU and bandwidth by using our re-streaming
        service.`}
      />
      <Header />
      <section className="bg-primary-alt header-inner">
        <div className="container">
          <div className="row my-3">
            <div className="col">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="index.html">Home</a>
                  </li>
                  {/* TODO: Services listing page, like the directory page */}
                  <li className="breadcrumb-item">
                    <a href={() => false}>Services</a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row justify-content-center my-4">
            <div className="col-auto">
              {imageError ? (
                <i className="fas fa-server fa-3x" />
              ) : (
                  <img
                    src={service.iconStyle}
                    onError={() => setImageError(true)}
                    className="icon icon-lg"
                    alt={`Live Stream to ${service.type} with StreamOn`}
                  />
                )}
              <h1 className="mx-2 mt-4 d-inline-block">+</h1>
              {imageError2 ? (
                <i className="fas fa-server fa-3x" />
              ) : (
                  <img
                    src={secondService.iconStyle}
                    onError={() => setImageError2(true)}
                    className="icon icon-lg"
                    alt={`Live Stream to ${secondService.type} with StreamOn`}
                  />
                )}
            </div>
          </div>
          <div className="row justify-content-center text-center">
            <div className="col-lg-9 col-xl-8">
              {/* TODO: featured service badge if featured */}
              <h1>
                Live Stream to
                {' '}
                {capitalize(service.type)}
                {' '}
                and
                {' '}
                {capitalize(secondService.type)}
                {' '}
                with
                StreamOn
              </h1>
            </div>
          </div>
          <div className="row my-6 justify-content-between">
            <div className="col-lg-7 col-xl-8 mb-3 mb-lg-0">
              {/* TODO: use the auto generated images from the next Trello task */}
              <img
                src={`https://streamon-public-assets.s3.eu-west-1.amazonaws.com/thumbnails/service-${slug}-and-${slug1}.png`}
                alt="Service Combination"
                className="rounded"
              />
            </div>
            <div className="col-lg-4 col-xl-3">
              <div className="mb-3">
                <h6 className="mb-1">{capitalize(service.type)}</h6>
                <p>{service.description}</p>
                <a href={service.websiteUrl}>{service.websiteUrl}</a>
              </div>
              <div className="mb-3">
                <h6 className="mb-1">{capitalize(secondService.type)}</h6>
                <p>{secondService.description}</p>
                <a href={secondService.websiteUrl}>{secondService.websiteUrl}</a>
              </div>
            </div>
          </div>
        </div>
        <div className="divider">
          <img src="assets/img/dividers/divider-1.svg" alt="graphical divider" data-inject-svg />
        </div>
      </section>
      <section className="p-0" data-reading-position>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10">
              <article className="article">
                <p className="lead">
                  Go live to
                  {' '}
                  {capitalize(capitalize(service.type))}
                  {' '}
                  and
                  {' '}
                  {capitalize(secondService.type)}
                  {' '}
                  using StreamOn. Increase the quality of your
                  streams and reduce the strain on your CPU and bandwidth by using our re-streaming
                  service.
                </p>
                {/* <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                  doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                  veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                  voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur
                  magni dolores eos qui ratione voluptatem sequi nesciunt.
                </p> */}
                <strong>
                  Using StreamOn you can use
                  {' '}
                  {capitalize(service.type)}
                  {' '}
                  with all of these great
                  features and more:
                </strong>
                <ul className="mt-2">
                  <li>
                    Unlimited live stream forwarding with
                    {capitalize(service.type)}
                  </li>
                  <li>
                    Commssion free tipping with
                    {capitalize(service.type)}
                  </li>
                  <li>
                    Email subscriptions with
                    {capitalize(service.type)}
                  </li>
                </ul>
              </article>
            </div>
          </div>
        </div>
      </section>
      {/* Other Services Section */}
      <section className="p-0 my-6" data-reading-position>
        <div className="container">
          <div className="row text-center w-100">
            <div className="col layer-2 w-100">
              <h2 className="h1">Other Re-Stream Service Combinations</h2>
              <div className="lead mb-4">Try some of the 60+ services supported by StreamOn</div>

              {/* <Link to='/'>
                <button className='btn btn-primary'>{t('directory.button')}</button>
              </Link> */}
            </div>
          </div>
          <div className="row justify-content-center mb-md-3 mt-3">
            {/* TODO: show the logos of the other service combinations - limit to 12 random other services combinations */}
            {matrix.map((parServices, i) => (
              <a
                className="w-50"
                href={`/service/${parServices[0].formatType}/and/${parServices[1].formatType}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {parServices.map((randomService, index) => {
                  if (
                    randomService.formatType === 'rtmpserver'
                    || randomService.formatType === 'taryana-apachat|تاریانا-آپاچت'
                  ) {
                    return (
                      <>
                        <i className="fas fa-server fa-3x" />
                        <span className="ml-3 truncate">{capitalize(randomService.type)}</span>
                        {index % 2 === 0 && <h1 className="mx-2 mt-4 d-inline-block">+</h1>}
                      </>
                    );
                  }
                  return (
                    <>
                      <img
                        src={randomService.iconStyle}
                        className="icon icon-lg"
                        alt={`Live Stream to ${randomService.type} with StreamOn`}
                        onError={(e) => (e.target.style.display = 'none')}
                      />
                      <span className="ml-3 truncate">{capitalize(randomService.type)}</span>
                      {index % 2 === 0 && <h1 className="mx-2 mt-4 d-inline-block">+</h1>}
                    </>
                  );
                })}
              </a>
            ))}
          </div>
        </div>
      </section>
      <section className="bg-primary-3 text-light o-hidden">
        <div className="container layer-1">
          <div className="row justify-content-center text-center mb-3">
            <div className="col-xl-8 col-lg-9">
              <h2 className="display-4 mx-xl-6">
                Stream to
                {' '}
                {capitalize(service.type)}
                {' '}
                and 60+ services
              </h2>
              <h4>Sign up for a StreamOn account for FREE unlimited re-streaming</h4>
            </div>
          </div>
          <div className="row justify-content-center">
            <a href="https://streamon.app/signup" className="btn btn-primary-2 btn-lg ml-lg-3">
              Join BETA 4
            </a>
          </div>
        </div>
      </section>
      <section className="bg-primary-alt">
        <div className="container">
          <div className="row mb-4">
            <h3 className="h2">Related Posts from the Blog</h3>
          </div>
          <div className="d-flex">
            <PreviewPosts ghostPosts={ghostPosts} />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ServiceCombinationsPage;
