const flags = {
  onAir: false,
  volumeBar: false,
  screenSharing: false,
  locale: false,
};

// How to set your own localstorage https://developers.google.com/web/tools/chrome-devtools/storage/localstorage
const mergeFlags = () => {
  // The latest parameter of the {...obj} wil overwrite the properties of the first
  // onAirFlags will be a json object which can have the same keys as the object flags
  // TODO: enable all on development NODE_ENV
  const localStorageVar = JSON.parse(localStorage.getItem('onAirFlags')) || {};
  return { ...flags, ...localStorageVar };
};

export default mergeFlags;
