import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '_assets/images/logo-poly.svg';
import { useTranslation } from 'react-i18next';
import trackEvent from '_utils/events';

const NotFoundPage = ({ error }) => {
  const { t } = useTranslation();

  console.log('error', error);

  useEffect(() => {
    trackEvent({ event: 'Page - Not found' });
  }, []);

  return (
    <section className="min-vh-100 bg-primary-3 text-light py-5 o-hidden">
      <div className="container">
        <div className="row justify-content-center mb-md-6">
          <div className="col-auto">
            <Link to="/">
              <img height="143" src={Logo} alt="StreamOn" />
            </Link>
          </div>
        </div>
        <div className="row text-center py-6">
          <div className="col layer-2">
            {error ? (
              <h2>{error}</h2>
            ) : (
                <div>
                  <h1 className="display-1 mb-0">404</h1>
                  <h2 className="h1">{t('notFound.title')}</h2>
                  <div className="lead mb-4">{t('notFound.subtitle')}</div>
                </div>
              )}
            <Link to="/">
              <button className="btn btn-primary btn-lg">{t('notFound.back')}</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;
