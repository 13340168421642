import React from 'react';
import ReactDOM from 'react-dom';

// Init Sentry Bug tracking
import * as Sentry from '@sentry/react';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '_store';
import './index.css';
import App from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';

// Init Sentry Bug tracking only on production
if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://d1ba4791007549019175e3f69774622d@o418625.ingest.sentry.io/5324319' });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
