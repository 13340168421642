import React from 'react';
import { Button } from 'react-bootstrap';

const LaunchOnAirButton = () => {
  // Params for Window browser stream
  const params = 'scrollbars=no,status=no,location=no,toolbar=no,menubar=no';

  return (
    <Button
      className="pulsingButton"
      variant="primary"
      onClick={() => window.open('/onair', 'StreamOn - On Air', params)}
    >
      Launch OnAir
    </Button>
  );
};

export default LaunchOnAirButton;
