import setUpActionsTypes from '_actionTypes/setUpAction.types';

const INITIAL_STATE = {
  loading: false,
  errors: {},
  loadingDesk: false,
  statusSuccessDesk: undefined,
  statusFailureDesk: undefined,
};
const setUpActionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case setUpActionsTypes.SETUP_ACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case setUpActionsTypes.SETUP_ACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case setUpActionsTypes.SETUP_ACTIONS_FAIL: {
      let errors = { error: 'Undefined Error' };
      if (action.error.response.data) {
        errors = action.error.response.data;
      }
      return {
        ...state,
        loading: false,
        errors,
      };
    }
    case setUpActionsTypes.DELETE_ACTIONS_REQUEST:
      return {
        ...state,
      };
    case setUpActionsTypes.DELETE_ACTIONS_SUCCESS:
      return {
        ...state,
      };
    case setUpActionsTypes.DELETE_ACTIONS_FAIL: {
      let errors = { error: 'Undefined Error' };
      if (action.error.response.data) {
        errors = action.error.response.data;
      }
      return {
        ...state,
        errors,
      };
    }
    case setUpActionsTypes.UPDATE_ORDER_REQUEST:
      return {
        ...state,
      };
    case setUpActionsTypes.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
      };
    case setUpActionsTypes.UPDATE_ORDER_FAIL: {
      let errors = { error: 'Undefined Error' };
      if (action.error.response.data) {
        errors = action.error.response.data;
      }
      return {
        ...state,
        errors,
      };
    }
    case setUpActionsTypes.RESET_STATUS_ACTION:
      return {
        ...state,
        statusSuccessDesk: false,
        statusFailureDesk: false,
      };
    case setUpActionsTypes.ACTION_DESK_REQUEST:
      return {
        ...state,
        loadingDesk: action.payload.request.data.action._id,
        statusSuccessDesk: false,
        statusFailureDesk: false,
      };
    case setUpActionsTypes.ACTION_DESK_SUCCESS:
      return {
        ...state,
        loadingDesk: false,
        statusSuccessDesk: action.payload.data.action._id,
        statusFailureDesk: false,
      };
    case setUpActionsTypes.ACTION_DESK_FAIL: {
      let errors = { error: 'Undefined Error' };
      if (action.error.response.data) {
        errors = action.error.response.data;
      }
      return {
        ...state,
        loadingDesk: false,
        statusSuccessDesk: false,
        statusFailureDesk: action.error.response.data.action._id,
        errors,
      };
    }
    default:
      return state;
  }
};

export default setUpActionsReducer;
