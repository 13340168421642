import mainUserType from '_actionTypes/mainUser.types';
import { API_URLS } from '_constants/api';
// import { boolean } from 'yup';

const changeSettingsRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      mainUserType.SETTINGS_REQUEST,
      mainUserType.SETTINGS_SUCCESS,
      mainUserType.SETTINGS_FAIL,
    ], // Axios Redux middleware makes requests and calls the relevant action based on success or failure https://www.npmjs.com/package/redux-axios-middleware
    payload: {
      request: {
        url: `${API_URLS.users}/settings`,
        method: 'post',
        data: {
          ...formValues,
        },
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};

export const updateSettings = (formValues) => (dispatch) => {
  dispatch(changeSettingsRequest(formValues))
    .then(() => { })
    .catch(() => { });
};
const refreshStreamkeyRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      mainUserType.STREAMKEY_REQUEST,
      mainUserType.STREAMKEY_SUCCESS,
      mainUserType.STREAMKEY_FAIL,
    ], // Axios Redux middleware makes requests and calls the relevant action based on success or failure https://www.npmjs.com/package/redux-axios-middleware
    payload: {
      request: {
        url: `${API_URLS.users}/settings/streamkey`,
        method: 'post',
        data: {
          ...formValues,
        },
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};

export const refreshStreamKey = (formValues) => (dispatch) => {
  dispatch(refreshStreamkeyRequest(formValues))
    .then(() => { })
    .catch(() => { });
};
const darkModeActionRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      mainUserType.DARK_MODE_REQUEST,
      mainUserType.DARK_MODE_SUCCESS,
      mainUserType.DARK_MODE_FAIL,
    ], // Axios Redux middleware makes requests and calls the relevant action based on success or failure https://www.npmjs.com/package/redux-axios-middleware
    payload: {
      request: {
        url: `${API_URLS.users}/settings/darkmode`,
        method: 'post',
        data: {
          ...formValues,
        },
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};

export const darkModeAction = (formValues) => (dispatch) => {
  dispatch(darkModeActionRequest(formValues))
    .then(() => { })
    .catch(() => { });
};
const changeSettingsPasswordRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      mainUserType.SETTINGS_PASSWORD_REQUEST,
      mainUserType.SETTINGS_PASSWORD_SUCCESS,
      mainUserType.SETTINGS_PASSWORD_FAIL,
    ], // Axios Redux middleware makes requests and calls the relevant action based on success or failure https://www.npmjs.com/package/redux-axios-middleware
    payload: {
      request: {
        url: `${API_URLS.users}/settings/password`,
        method: 'post',
        data: {
          ...formValues,
        },
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};

export const updateSettingsPassword = (formValues) => (dispatch) => {
  dispatch(changeSettingsPasswordRequest(formValues))
    .then(() => { })
    .catch(() => { });
};
const stripeRegisterRequest = (email) => (dispatch) => {
  const action = {
    types: [mainUserType.STRIPE_REQUEST, mainUserType.STRIPE_SUCCESS, mainUserType.STRIPE_FAIL], // Axios Redux middleware makes requests and calls the relevant action based on success or failure https://www.npmjs.com/package/redux-axios-middleware
    payload: {
      request: {
        url: `${API_URLS.users}/stripe`,
        method: 'post',
        data: { email },

        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};

export const stripeRegister = (email) => (dispatch) => {
  dispatch(stripeRegisterRequest(email))
    .then(() => { })
    .catch(() => { });
};

const setSuccessSubscriptionMessage = (messageSubscription) => ({
  type: mainUserType.SUCCESS_MESSAGE_SUBSCRIPTION,
  messageSubscription,
});

const setSubscriptionFailure = (messageSubscription) => ({
  type: mainUserType.FAILURE_MESSAGE_SUBSCRIPTION,
  messageSubscription,
});

const stripeSubscriptionRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      mainUserType.SUBSCRIPTION_REQUEST,
      mainUserType.SUBSCRIPTION_SUCCESS,
      mainUserType.SUBSCRIPTION_FAIL,
    ], // Axios Redux middleware makes requests and calls the relevant action based on success or failure https://www.npmjs.com/package/redux-axios-middleware
    payload: {
      request: {
        url: `${API_URLS.users}/${formValues.url}`,
        method: 'post',
        data: { ...formValues },

        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};

export const stripeSubscription = (formValues) => (dispatch) => {
  dispatch(stripeSubscriptionRequest(formValues))
    // If the card is declined, display an error to the user.
    .then((result) => {
      if (result.payload.data.update === false) {
        if (result.payload.data.subscription.latest_invoice.payment_intent.status === 'succeeded') {
          dispatch(setSuccessSubscriptionMessage(result.payload.data.message));
        } else if (
          result.payload.data.subscription.latest_invoice.payment_intent.status
          === 'requires_payment_method'
          || result.payload.data.subscription.latest_invoice.payment_intent.status
          === 'requires_action'
        ) {
          // TODO: Manage subscription payment failure, redirect them to the payment
          // form and send to the server the invoceId to update the payment details
          dispatch(setSubscriptionFailure('Payment Declined'));
        }
      } else {
        dispatch(setSuccessSubscriptionMessage(result.payload.data.message));
      }
    })
    .catch(() => { });
};

const getInfoOfUser = (id) => (dispatch) => {
  const action = {
    types: [
      mainUserType.INFO_USER_REQUEST,
      mainUserType.INFO_USER_SUCCESS,
      mainUserType.INFO_USER_FAIL,
    ], // Axios Redux middleware makes requests and calls the relevant action based on success or failure https://www.npmjs.com/package/redux-axios-middleware
    payload: {
      request: {
        url: `${API_URLS.users}/settings/${id}`,
        method: 'get',
        data: {
          ...id,
        },
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};

export const infoUser = (id) => (dispatch) => {
  dispatch(getInfoOfUser(id))
    .then(() => { })
    .catch(() => { });
};

export const clearSettingsStore = () => ({
  type: mainUserType.RESET_SETTINGS,
});
export const updateUser = (user) => ({
  type: mainUserType.USER_UPDATE,
  user,
});
export const updateLiveStatus = (status) => ({
  type: mainUserType.STATUS_UPDATE,
  status,
});
export const updateLiveTime = (time) => ({
  type: mainUserType.TIME_UPDATE,
  time,
});

// export const updateDarkMode = mode => {
//   return {
//     type: mainUserType.DARK_MODE_UPDATE,
//     mode,
//   };
// };

export const showBilling = (booleanBilling) => ({
  type: mainUserType.BILLING_SHOW,
  booleanBilling,
});
export const setUpdateCard = (updateCard) => ({
  type: mainUserType.STATE_CARD,
  updateCard,
});
export const refreshErrorSubscription = () => ({
  type: mainUserType.ERROR_SUBSCRIPTION,
});

const cancelSubscriptionRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      mainUserType.CANCEL_SUBSCRIPTION_REQUEST,
      mainUserType.CANCEL_SUBSCRIPTION_SUCCESS,
      mainUserType.CANCEL_SUBSCRIPTION_FAIL,
    ], // Axios Redux middleware makes requests and calls the relevant action based on success or failure https://www.npmjs.com/package/redux-axios-middleware
    payload: {
      request: {
        url: `${API_URLS.users}/cancel-subscription`,
        method: 'post',
        data: {
          subscriptionId: formValues,
        },
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};

export const cancelSubscription = (formValues) => (dispatch) => {
  dispatch(cancelSubscriptionRequest(formValues))
    .then(() => { })
    .catch(() => { });
};

const uploadOverlayRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      mainUserType.UPLOAD_OVERLAY_REQUEST,
      mainUserType.UPLOAD_OVERLAY_SUCCESS,
      mainUserType.UPLOAD_OVERLAY_FAIL,
    ], // Axios Redux middleware makes requests and calls the relevant action based on success or failure https://www.npmjs.com/package/redux-axios-middleware
    payload: {
      request: {
        url: `${API_URLS.users}/upload/overlays`,
        method: 'post',
        data: {
          ...formValues,
        },
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};

export const uploadOverlay = (formValues) => (dispatch) => {
  dispatch(uploadOverlayRequest(formValues))
    .then(() => { })
    .catch(() => { });
};
const selectOverlayRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      mainUserType.SELECT_OVERLAY_REQUEST,
      mainUserType.SELECT_OVERLAY_SUCCESS,
      mainUserType.SELECT_OVERLAY_FAIL,
    ], // Axios Redux middleware makes requests and calls the relevant action based on success or failure https://www.npmjs.com/package/redux-axios-middleware
    payload: {
      request: {
        url: `${API_URLS.users}/select/overlays`,
        method: 'post',
        data: {
          ...formValues,
        },
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};

export const selectOverlay = (formValues) => (dispatch) => {
  dispatch(selectOverlayRequest(formValues))
    .then(() => { })
    .catch(() => { });
};
const deleteOverlayRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      mainUserType.DELETE_OVERLAY_REQUEST,
      mainUserType.DELETE_OVERLAY_SUCCESS,
      mainUserType.DELETE_OVERLAY_FAIL,
    ], // Axios Redux middleware makes requests and calls the relevant action based on success or failure https://www.npmjs.com/package/redux-axios-middleware
    payload: {
      request: {
        url: `${API_URLS.users}/delete/overlays`,
        method: 'post',
        data: {
          ...formValues,
        },
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};

export const deleteOverlay = (formValues) => (dispatch) => {
  dispatch(deleteOverlayRequest(formValues))
    .then(() => { })
    .catch(() => { });
};

const getOverlaysSignedUrlRequest = (id) => (dispatch) => {
  const action = {
    types: [
      mainUserType.SIGNED_URL_OVERLAY_REQUEST,
      mainUserType.SIGNED_URL_OVERLAY_SUCCESS,
      mainUserType.SIGNED_URL_OVERLAY_FAIL,
    ], // Axios Redux middleware makes requests and calls the relevant action based on success or failure https://www.npmjs.com/package/redux-axios-middleware
    payload: {
      request: {
        url: `${API_URLS.users}/signedurl/overlays/${id}`,
        method: 'get',
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};

export const getOverlaysSignedUrl = (id) => (dispatch) => {
  dispatch(getOverlaysSignedUrlRequest(id))
    .then(() => { })
    .catch(() => { });
};
