import mainUserType from '_actionTypes/mainUser.types';

const INITIAL_STATE = {
  mainUser: {},
  loading: false,
  loadingSubscriptionrequest: false,
  loadingCancelrequest: false,
  errors: {},
  subscriptionErrors: undefined,
  subscriptionSuccess: undefined,
  billing: false,
  updateCard: false,
  imagesOverlays: [],
  loadingOverlay: false,
  loadingSelect: false,
  loadingDelete: false,
};

let errors;
let flag;
const uiState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case mainUserType.RESET_SETTINGS:
      return INITIAL_STATE;
    case mainUserType.INFO_USER_REQUEST:
    case mainUserType.SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
      };
    case mainUserType.INFO_USER_SUCCESS:
      return {
        ...state,
        mainUser: action.payload.data,
        loading: false,
        errors: {},
      };
    case mainUserType.SETTINGS_SUCCESS: {
      const {
        messageEmail,
        message,
        messageMagicLink,
      } = action.payload.data;
      return {
        ...state,
        loading: false,
        mainUser: action.payload.data.el,
        errors: {
          messageEmail,
          message,
          messageMagicLink,
        },
      };
    }
    case mainUserType.INFO_USER_FAIL:
    case mainUserType.SETTINGS_FAIL: {
      // Log the errors into Redux here
      return {
        ...state,
        loading: false,
        errors: action.error.response.data ? action.error.response.data : { error: 'Undefined Error' },
      };
    }
    case mainUserType.SETTINGS_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
      };
    case mainUserType.SETTINGS_PASSWORD_SUCCESS: {
      const {
        messageUpdatedPassword,
        passwordMessage,
      } = action.payload.data;
      return {
        ...state,
        loading: false,
        mainUser: action.payload.data.el,
        errors: {
          messageUpdatedPassword,
          passwordMessage,
        },
      };
    }
    case mainUserType.SETTINGS_PASSWORD_FAIL: {
      // Log the errors into Redux here
      return {
        ...state,
        loading: false,
        errors: action.error.response.data ? action.error.response.data : { error: 'Undefined Error' },
      };
    }
    case mainUserType.STREAMKEY_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
      };
    case mainUserType.STREAMKEY_SUCCESS: {
      const {
        tokenMessage,
      } = action.payload.data;
      return {
        ...state,
        loading: false,
        mainUser: action.payload.data.el,
        errors: {
          tokenMessage,
        },
      };
    }
    case mainUserType.STREAMKEY_FAIL: {
      // Log the errors into Redux here
      return {
        ...state,
        loading: false,
        errors: action.error.response.data ? action.error.response.data : { error: 'Undefined Error' },
      };
    }
    case mainUserType.DARK_MODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case mainUserType.DARK_MODE_SUCCESS: {
      return {
        ...state,
        loading: false,
        mainUser: action.payload.data.el,
      };
    }
    case mainUserType.DARK_MODE_FAIL: {
      // Log the errors into Redux here
      return {
        ...state,
        loading: false,
      };
    }
    case mainUserType.STRIPE_REQUEST:
      return {
        ...state,
        subscriptionErrors: undefined,
        subscriptionSuccess: undefined,
        loading: false,
      };
    case mainUserType.STRIPE_SUCCESS: {
      const { user, message } = action.payload.data;
      return {
        ...state,
        loading: false,
        mainUser: user,
        errors: message,
      };
    }
    case mainUserType.STRIPE_FAIL:
      // Log the errors into Redux here
      return {
        ...state,
        loading: false,
        errors: action.error.response.data ? action.error.response.data : { error: 'Undefined Error' },
      };
    case mainUserType.SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loadingSubscriptionrequest: true,
        subscriptionErrors: undefined,
        subscriptionSuccess: undefined,
        loading: false,
      };
    case mainUserType.SUBSCRIPTION_SUCCESS: {
      const { user, message } = action.payload.data;
      return {
        ...state,
        loadingSubscriptionrequest: false,
        loading: false,
        mainUser: user,
        errors: message,
        subscriptionErrors: undefined,
        billing: false,
      };
    }
    case mainUserType.SUBSCRIPTION_FAIL:
      // store subscription failure
      flag = false;
      errors = { error: 'Undefined Error' };
      if (action.error.response.data) {
        errors = action.error.response.data;
        if (action.error.response.data.message === 'This Coupon Code is Invalid') {
          flag = true;
        }
      }
      // Log the errors into Redux here
      return {
        ...state,
        loadingSubscriptionrequest: false,
        loading: false,
        errors,
        subscriptionErrors: errors.message ? errors.message : errors,
        updateCard: flag ? true : state.updateCard,
      };
    case mainUserType.ERROR_SUBSCRIPTION:
      return {
        ...state,
        subscriptionErrors: undefined,
        subscriptionSuccess: undefined,
        loadingSubscriptionrequest: false,
        loadingCancelrequest: false,
      };

    case mainUserType.SUCCESS_MESSAGE_SUBSCRIPTION:
      return {
        ...state,
        subscriptionSuccess: action.messageSubscription,
        billing: false,
      };
    case mainUserType.FAILURE_MESSAGE_SUBSCRIPTION:
      return {
        ...state,
        subscriptionErrors: action.messageSubscription,
      };
    case mainUserType.BILLING_SHOW:
      return {
        ...state,
        billing: action.booleanBilling,
      };
    case mainUserType.STATE_CARD:
      return {
        ...state,
        updateCard: action.updateCard,
      };

    case mainUserType.CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loadingCancelrequest: true,
        subscriptionErrors: undefined,
        subscriptionSuccess: undefined,
        loading: false,
        errors: {},
      };
    case mainUserType.CANCEL_SUBSCRIPTION_SUCCESS: {
      const { user, message } = action.payload.data;
      return {
        ...state,
        loadingCancelrequest: false,
        loading: false,
        mainUser: user,
        subscriptionSuccess: message,
        billing: false,
      };
    }
    case mainUserType.CANCEL_SUBSCRIPTION_FAIL:
      // store subscription failure
      errors = { error: 'Undefined Error' };
      if (action.error.response.data) {
        errors = action.error.response.data;
      }
      // Log the errors into Redux here
      return {
        ...state,
        loadingCancelrequest: false,
        loading: false,
        errors,
      };

    case mainUserType.USER_UPDATE: {
      return { ...state, mainUser: action.user };
    }
    case mainUserType.STATUS_UPDATE: {
      return { ...state, mainUser: { ...state.mainUser, isLive: action.status } };
    }
    case mainUserType.TIME_UPDATE: {
      return { ...state, mainUser: { ...state.mainUser, isLiveTimestamp: action.time } };
    }
    case mainUserType.UPLOAD_OVERLAY_REQUEST:
      return {
        ...state,
        loadingOverlay: true,
      };
    case mainUserType.UPLOAD_OVERLAY_SUCCESS: {
      const { user } = action.payload.data;
      return {
        ...state,
        loadingOverlay: false,
        mainUser: user,
      };
    }
    case mainUserType.UPLOAD_OVERLAY_FAIL:
      return {
        ...state,
        loadingOverlay: false,
      };
    case mainUserType.SELECT_OVERLAY_REQUEST:
      return {
        ...state,
        loadingSelect: true,
      };
    case mainUserType.SELECT_OVERLAY_SUCCESS: {
      const { user } = action.payload.data;
      return {
        ...state,
        loadingSelect: false,
        mainUser: user,
      };
    }
    case mainUserType.SELECT_OVERLAY_FAIL:
      return {
        ...state,
        loadingSelect: false,
      };
    case mainUserType.DELETE_OVERLAY_REQUEST:
      return {
        ...state,
        loadingDelete: true,
      };
    case mainUserType.DELETE_OVERLAY_SUCCESS: {
      const { user } = action.payload.data;
      return {
        ...state,
        loadingDelete: false,
        mainUser: user,
      };
    }
    case mainUserType.DELETE_OVERLAY_FAIL:
      return {
        ...state,
        loadingDelete: false,
      };
    case mainUserType.SIGNED_URL_OVERLAY_REQUEST:
      return {
        ...state,
        loadingDelete: true,
      };
    case mainUserType.SIGNED_URL_OVERLAY_SUCCESS: {
      const { user } = action.payload.data;
      return {
        ...state,
        loadingDelete: false,
        mainUser: user,
      };
    }
    case mainUserType.SIGNED_URL_OVERLAY_FAIL:
      return {
        ...state,
        loadingDelete: false,
      };
    default:
      return state;
  }
};

export default uiState;
