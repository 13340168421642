export default {
  SETUP_ACTIONS_REQUEST: 'SETUP_ACTIONS/SETUP_ACTIONS_REQUEST',
  SETUP_ACTIONS_SUCCESS: 'SETUP_ACTIONS/SETUP_ACTIONS_SUCCESS',
  SETUP_ACTIONS_FAIL: 'SETUP_ACTIONS/SETUP_ACTIONS_FAIL',

  DELETE_ACTIONS_REQUEST: 'DELETE_ACTIONS/DELETE_ACTIONS_REQUEST',
  DELETE_ACTIONS_SUCCESS: 'DELETE_ACTIONS/DELETE_ACTIONS_SUCCESS',
  DELETE_ACTIONS_FAIL: 'DELETE_ACTIONS/DELETE_ACTIONS_FAIL',

  UPDATE_ORDER_REQUEST: 'UPDATE_ORDER/UPDATE_ORDER_REQUEST',
  UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER/UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_FAIL: 'UPDATE_ORDER/UPDATE_ORDER_FAIL',

  ACTION_DESK_REQUEST: 'ACTION_DESK/ACTION_DESK_REQUEST',
  ACTION_DESK_SUCCESS: 'ACTION_DESK/ACTION_DESK_SUCCESS',
  ACTION_DESK_FAIL: 'ACTION_DESK/ACTION_DESK_FAIL',

  RESET_STATUS_ACTION: 'RESET_STATUS/RESET_STATUS_ACTION',
};
