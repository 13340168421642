import React from 'react';
import {
  func, bool, string, element,
} from 'prop-types';
import { Button as ButtonRB } from 'react-bootstrap';
import classnames from 'classnames';

const Button = ({
  className,
  disabled,
  variant,
  icon,
  onClick,
  text,
  type,
  toUppercase,
  ...props
}) => (
  <ButtonRB
    {...props}
    type={type}
    variant={variant}
    className={classnames('btn', className)}
    onClick={onClick}
    disabled={disabled}
  >
    {icon}
    {text && toUppercase ? text.toUpperCase() : text}
  </ButtonRB>
);

Button.propTypes = {
  type: string,
  className: string,
  disabled: bool,
  variant: string,
  icon: element,
  onClick: func,
  text: string,
  toUppercase: bool,
};

Button.defaultProps = {
  type: 'button',
  className: '',
  disabled: false,
  variant: '',
  icon: null,
  onClick: () => {},
  text: null,
  toUppercase: false,
};

export default Button;
