import audioType from '_actionTypes/audio.types';

const INITIAL_STATE = {
  loadingLoop: false,
  errors: {},
};
const audioReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case audioType.LOOP_REQUEST:
      return {
        ...state,
        loadingLoop: true,
      };
    case audioType.LOOP_SUCCESS:
      return {
        ...state,
      };
    case audioType.LOOP_FAIL: {
      let errors = { error: 'Undefined Error' };
      if (action.error.response.data) {
        errors = action.error.response.data;
      }
      // Log the errors into Redux here
      return {
        ...state,
        errors,
      };
    }
    case audioType.UPDATE_LOOP:
      return {
        ...state,
        loadingLoop: false,
      };
    default:
      return state;
  }
};

export default audioReducer;
