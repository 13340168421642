import React from 'react';

const authServices = [
  {
    type: 'facebook',
    provider: 'facebook',
    className: 'btn btn-block btn-facebook btn-provider',
    icon: <i className="fab fa-facebook-square mr-1" />,
    text: 'Facebook Live',
  },
  {
    type: 'youtube',
    provider: 'youtube',
    className: 'btn btn-block btn-google btn-provider',
    icon: <i className="fab fa-youtube mr-1" />,
    text: 'Youtube Live',
  },
  {
    type: 'twitch',
    provider: 'twitch',
    className: 'btn btn-block btn-twitch btn-provider',
    icon: <i className="fab fa-twitch mr-1" />,
    text: 'Twitch',
  },
];

export default authServices;
