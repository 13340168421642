import React, { useState, useEffect } from 'react';
import api from '_GhostContentAPI/GhostContentAPI';
import { useParams } from 'react-router-dom';
import PostCard from '_components/_MarketingBlogPage/PostCard';
import Newsletter from '_components/_MarketingBlogPage/Newsletter/index';
import PopularPosts from '_components/_MarketingBlogPage/PopularPosts';
import history from '_utils/history';
import Header from '_components/_MarketingBlogPage/Header';
import Footer from '_components/_MarketingBlogPage/Footer';

// Assets
import blogHeader from '_assets/assetsBlog/img/blog-header.jpg';
import MetaTags from '_components/MetaTags';

const Authors = () => {
  const { slug } = useParams();
  const [postWithTag, setPostWithTag] = useState([]);
  const [ghostAuthor, setGhostAuthor] = useState(undefined);
  const [ghostPosts, setGhostPosts] = useState([]);

  const fetchTag = () => {
    api.posts
      .browse({ filter: `authors:[${slug}]`, include: 'tags,authors' })
      .then((posts) => {
        setPostWithTag(posts);
      })
      .catch((err) => {
        console.error(err);
      });

    api.posts
      .browse({ include: 'tags,authors' })
      .then((posts) => setGhostPosts(posts))
      .catch((err) => {
        console.error(err);
      });
    api.authors
      .read({ slug })
      .then((author) => {
        setGhostAuthor(author);
        if (author === undefined) {
          history.push('/404');
        }
      })
      .catch((err) => {
        console.error(err);
        history.push('/404');
      });
  };

  useEffect(() => {
    fetchTag();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Hanlding not fouund page
  return (
    <>
      {ghostAuthor ? (
        <>
          <MetaTags
            title={ghostAuthor.name}
            image={ghostAuthor.profile_image}
            description={`Read ${ghostAuthor.name}'s posts on the StreamOn blog.`}
          />
          <Header />
          <section
            className="has-divider text-light jarallax bg-dark"
            data-jarallax
            data-speed="0.5"
            data-overlay
          >
            <img src={blogHeader} alt="" className="jarallax-img opacity-50" />
            <div className="container">
              <div className="row">
                <div className="col-xl-5 col-lg-6 col-md-8">
                  {ghostAuthor.profile_image ? (
                    <img
                      className="avatar author-profile-image mb-3"
                      src={ghostAuthor.profile_image}
                      alt={ghostAuthor.name}
                    />
                  ) : (
                      <span className="avatar author-profile-image mb-3">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="M3.513 18.998C4.749 15.504 8.082 13 12 13s7.251 2.504 8.487 5.998C18.47 21.442 15.417 23 12 23s-6.47-1.558-8.487-4.002zM12 12c2.21 0 4-2.79 4-5s-1.79-4-4-4-4 1.79-4 4 1.79 5 4 5z"
                              fill="#FFF"
                            />
                          </g>
                        </svg>
                      </span>
                    )}
                  <h1 className="display-4">{ghostAuthor.name}</h1>
                  <p className="lead mb-0">
                    <div className="site-header-content author-header">
                      <div className="author-header-content">
                        {ghostAuthor.bio && <h2 className="author-bio">{ghostAuthor.bio}</h2>}
                        <div className="author-meta">
                          {ghostAuthor.location && (
                            <h2 className="author-location">{ghostAuthor.location}</h2>
                          )}
                        </div>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section data-overlay>
            <div className="container">
              <div className="row mb-4">
                <div className="col-md-8 col-lg-9">
                  {postWithTag.map((post) => (
                    <PostCard post={post} key={post.id} />
                  ))}
                </div>
                <div className="col-md-4 col-lg-3 d-none d-md-block sticky-top">
                  <Newsletter />
                  <PopularPosts posts={ghostPosts} />
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      ) : null}
    </>
  );
};

export default Authors;
