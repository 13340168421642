// import SignUpPage from '_pages/SignUpPage';
// import LogInPage from '_pages/LogInPage';
// import ForgotPasswordPage from '_pages/ForgotPasswordPage';
import NotFoundPage from '_pages/NotFoundPage';
// import ConnectPage from '_pages/ConnectPage';
// import GoLivePage from '_pages/GoLivePage';
// import EngagePage from '_pages/EngagePage';
// import SettingsPage from '_pages/SettingsPage';
import UpgradePage from '_pages/UpgradePage';
import ProfilePage from '_pages/ProfilePage';
import EmailConfirmationPage from '_pages/EmailConfirmationPage';
import ResetPasswordPage from '_pages/ResetPasswordPage';
import DirectoryPage from '_pages/DirectoryPage';
import HomePage from '_pages/HomePage';
import Blog from '_pages/Blog';
import Posts from '_pages/Posts';
import Tag from '_pages/Tag';
import Authors from '_pages/Authors';
import AutoGeneratedPages from '_pages/AutoGeneratedPages';
import Pricing from '_pages/Pricing';
// import HighlightsPage from '_pages/HighlightsPage';
// import OnAir from '_pages/OnAir';

import ServicesPage from '_pages/ServicesPage';
import ServiceCombinationsPage from '_pages/ServiceCombinationsPage';
import ServiceIntegrationPage from '_pages/ServiceIntegrationPage';

const Pages = {
  // SignUpPage,
  // LogInPage,
  // ForgotPasswordPage,
  NotFoundPage,
  // ConnectPage,
  // GoLivePage,
  // EngagePage,
  // SettingsPage,
  UpgradePage,
  ProfilePage,
  EmailConfirmationPage,
  ResetPasswordPage,
  DirectoryPage,
  HomePage,
  Blog,
  Posts,
  ServicesPage,
  ServiceCombinationsPage,
  ServiceIntegrationPage,
  Tag,
  Pricing,
  // HighlightsPage,
  AutoGeneratedPages,
  Authors,
  // OnAir,
};

export default Pages;
