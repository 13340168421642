import React, { useState, useEffect } from 'react';
import api from '_GhostContentAPI/GhostContentAPI';
import Header from '_components/_MarketingBlogPage/Header';
import Footer from '_components/_MarketingBlogPage/Footer';
import PreviewPosts from '_components/_MarketingBlogPage/PreviewPosts';

// Assets
import saasSketch1 from '_assets/assetsBlog/img/saas-sketch-1.svg';
import saasSketch2 from '_assets/assetsBlog/img/saas-sketch-2.svg';
// import saasSketch3 from '_assets/assetsBlog/img/saas-sketch-3.svg';
import saasSketch4 from '_assets/assetsBlog/img/saas-sketch-4.svg';
import saasSketch5 from '_assets/assetsBlog/img/saas-sketch-5.svg';
import saasSketch6 from '_assets/assetsBlog/img/saas-sketch-6.svg';

import onAirPoster from '_assets/assetsBlog/img/on-air-loop-poster.jpg';

import MetaTags from '_components/MetaTags';

const HomePage = () => {
  const [ghostPosts, setGhostPosts] = useState([]);
  console.log('Blog -> ghostPosts', ghostPosts);
  const fetchPosts = () => {
    api.posts
      .browse({ limit: 3, include: 'tags,authors' })
      .then((posts) => setGhostPosts(posts))
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <>
      <MetaTags />
      <Header />
      <section className="bg-primary text-light o-hidden">
        <div className="container">
          <div className="row align-items-center min-vh-50">
            <div className="col-lg-6 text-center text-lg-left mb-4 mb-lg-0">
              <h1 className="display-3">
                Your Live Stream Starts Here
              </h1>
              <div className="my-4">
                <p className="lead">
                  Go live right from the browser, to YouTube, Twitch, Facebook and 60+ services. Increase your live stream quality. Reach new fans. Make Money.
                </p>
              </div>
              {/* {{!-- <a href="https://streamon.app/signup" className="btn btn-lg btn-primary-2">Join Now</a> --}} */}
              <a href="https://streamon.app/signup" className="btn btn-lg btn-primary-2">
                Join BETA 4
              </a>
            </div>
            <div className="col-lg-6 text-center" data-aos="fade-left">
              <img src={saasSketch1} alt="Go live" className="min-vw-60" />
            </div>
          </div>
        </div>
      </section>
      <section className="on-air-section">
        {/* <div className='container'>
          <div className='row mb-4'>
            <div className='col'>
              <h3 className='h1 on-air-section__title'>Try On Air</h3>
            </div>
          </div>
        </div> */}
        {/* Full Width On Air Demo Video */}
        <video playsInline autoPlay muted loop poster={onAirPoster} className="on-air-section__video">
          <source src="https://streamon-public-assets.s3.eu-west-1.amazonaws.com/homepage-videos/on-air-loop.webm" type="video/webm" />
          <source src="https://streamon-public-assets.s3.eu-west-1.amazonaws.com/homepage-videos/on-air-loop.mp4" type="video/mp4" />
        </video>
      </section>
      <section>
        <div className="container">
          <div className="row justify-content-center text-center mb-6">
            <div className="col-xl-8 col-lg-9">
              <h2 className="display-4 mx-xl-6">All the Tools You Need to Go Live</h2>
              <p className="lead">
                StreamOn brings you a suite of live-streaming tools to help you reach your audience
                online, grow your fanbase and make money.
              </p>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-0" data-aos="fade-up" data-aos-delay="200">
              <img src={saasSketch6} alt="Go live" className="mb-4" />
              <h5>
                Re-Stream
              </h5>
              <p className="px-xl-4">
                You're not limited to our browser streaming, use your existing encoder like OBS and XSplit to forward to 60+ services.
              </p>
            </div>
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-0" data-aos="fade-up" data-aos-delay="100">
              <img src={saasSketch2} alt="Go live" className="mb-4" />
              <h5>Magic Link</h5>
              <p className="px-xl-4">
                Go live to multiple platforms - share one simple link. Users see your profile and
                get redirected automatically to their platform of choice.
              </p>
            </div>
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-0" data-aos="fade-up" data-aos-delay="300">
              <img src={saasSketch4} alt="Go live" className="mb-4" />
              <h5>
                On Air
              </h5>
              <p className="px-xl-4">
                Invite guests and go live right from the browser to your connected platforms.
                No downloads required.
              </p>
            </div>
            {/* <div className='col-md-6 col-lg-3 mb-4 mb-lg-0' data-aos='fade-up' data-aos-delay='200'>
              <img src={saasSketch6} alt='Image' className='mb-4' />
              <h5>
                AI Tools <span className='badge badge-secondary'>BETA 5</span>
              </h5>
              <p className='px-xl-4'>
                Streaming is complex. Use bots to engage the chat, cut clips and edit hightlights.
                No additional software required.
              </p>
            </div> */}
            <div className="col-md-6 col-lg-3 mb-4 mb-lg-0" data-aos="fade-up" data-aos-delay="400">
              <img src={saasSketch5} alt="Go live" className="mb-4" />
              <h5>Open Roadmap</h5>
              <p className="px-xl-4">
                Join the discusson on our open roadmap and decide what we build next.
              </p>
              <a href="https://roadmap.streamon.app/" className="hover-arrow">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary-2-alt">
        <div className="container">
          <div className="row mb-4">
            <div className="col">
              <h3 className="h1">Latest from the Blog</h3>
            </div>
          </div>
          <div className="row">
            <PreviewPosts ghostPosts={ghostPosts} />
          </div>
          <div className="row">
            <div className="col">
              <a href="/blog" className="hover-arrow">
                View more
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary-3 text-light o-hidden">
        <div className="container layer-1">
          <div className="row justify-content-center text-center mb-3">
            <div className="col-xl-8 col-lg-9">
              <h2 className="display-4 mx-xl-6">What are you waiting for?</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <a href="https://streamon.app/signup" className="btn btn-primary-2 btn-lg ml-lg-3">
              Join BETA 4
            </a>
          </div>
        </div>
        {/* <div
        data-jarallax-element='0 50'
        >
          <div className='decoration bottom left scale-3'>
            <img
              className='bg-primary-2'
              src={decoBlob5}
              alt='deco-blob-5 decoration'
              data-inject-svg
            />
          </div>
        </div> */}
      </section>
      <Footer />
    </>
  );
};

export default HomePage;
