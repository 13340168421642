import React from 'react';
import logoWhite from '_assets/assetsBlog/img/logo-white.svg';
import { Link } from 'react-router-dom';
import authSelectors from '_selectors/auth.selectors';
import Gravatar from 'react-gravatar';
import DarkModeSwitch from '_components/DarkModeSwitch';
import { useTranslation } from 'react-i18next';
import { PATH } from '_routes/Routes';
import { useSelector, useDispatch } from 'react-redux';
import authActions from '_actions/auth.actions';
import { Button } from 'react-bootstrap';
import userSelectors from '_selectors/mainUser.selectors';

const Header = () => {
  const { t } = useTranslation();
  const { SETTINGS, UPGRADE } = PATH;

  const isAuthenticated = useSelector(authSelectors.getIsAuthenticated);

  const user = useSelector(userSelectors.getUserInfo);

  const dispatch = useDispatch();

  const handleLogOut = (e) => {
    // e.preventDefault();
    dispatch(authActions.logOutUser());
  };

  return (
    <header className="site-header">
      <div className="navbar-container">
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary" data-sticky="top">
          <div className="container">
            <Link class="navbar-brand fade-page" to="/">
              <img alt="StreamOn" src={logoWhite} />
              <span className="badge badge-secondary ml-2">BETA 4</span>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fas fa-bars icon navbar-toggler-open" />
              <i className="fas fa-times icon navbar-toggler-close" />
            </button>
            <div className="collapse navbar-collapse justify-content-end">
              <div className="py-2 py-lg-0">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="#" class="nav-link">
                      Home
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/pricing" class="nav-link">
                      Pricing
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a href="https://roadmap.streamon.dev" className="nav-link">
                      Roadmap
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link to="/blog" class="nav-link">
                      Blog
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/page/help" class="nav-link">
                      Help
                    </Link>
                  </li>
                  {isAuthenticated ? (
                    <Link to="/connect" class="btn btn-white ml-lg-3">
                      Dashboard
                    </Link>
                  ) : (
                      <Link to="/login" class="nav-link">
                        Log In
                      </Link>
                    )}
                </ul>
              </div>
              {isAuthenticated ? (
                <div className="py-2 py-lg-0">
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown dropleft">
                      <span
                        className="nav-link dropdown-toggle"
                        id="dropdown05"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fas fa-caret-down pr-2" />
                        {/* TODO: replace with user image uploaded to the magicLink page or settings? */}
                        <Gravatar
                          email={user.email}
                          size={100}
                          rating="pg"
                          className="avatar avatar-sm"
                        />
                      </span>
                      <div className="dropdown-menu" aria-labelledby="dropdown05">
                        <h6 className="dropdown-item">{user.name}</h6>
                        <Link className="dropdown-item" to={`/${user.magicLink}`} target="_blank">
                          <i className="fas fa-external-link-alt" />
                          {' '}
                          Magic Link
                        </Link>
                        <Link className="dropdown-item" to={UPGRADE.url}>
                          <i className="fa fa-credit-card" aria-hidden="true" />
                          {' '}
                          {t('header.subscription')}
                        </Link>
                        <Link className="dropdown-item" to={SETTINGS.url}>
                          <i className="fa fa-cog" aria-hidden="true" />
                          {' '}
                          {t('header.settings')}
                        </Link>
                        <hr />
                        <span className="dropdown-item">
                          <DarkModeSwitch />
                        </span>
                        <hr />
                        <span className="dropdown-item">
                          <Button variant="secondary" onClick={handleLogOut}>
                            {t('header.logout')}
                          </Button>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              ) : (
                null
              )}
            </div>
          </div>
        </nav>
      </div>
      <div className="navbar-container">
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary-3" data-sticky="top">
          <div className="container" style={{fontWeight: 'bold', fontSize: 30}}>
            News: StreamOn has been acquired. 
            <Link to="/post/news-streamon-has-been-acquired/" class="btn btn-white">
              Read More
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
