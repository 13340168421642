import React from 'react';
import Mailchimp from 'react-mailchimp-form';

const Newsletter = () => (
  <div className="mb-4">
    <h5>Newsletter</h5>

    <p>Get the latest live streaming tips via email.</p>

    <Mailchimp
      action="https://benbowler.us20.list-manage.com/subscribe/post?u=87f55d0594cbe94340129a4ef&amp;id=79cb9d7cc2"
      fields={[
        {
          // name: 'EMAIL',
          // placeholder: 'Email',
          // type: 'email',
          // required: true
          type: 'email',
          name: 'EMAIL',
          placeholder: 'Email Address',
          class: 'required email form-control mb-2',
          required: true,
        },
      ]}
    />

    {/* <div id='mc_embed_signup'>
        <form
          action='https://benbowler.us20.list-manage.com/subscribe/post?u=87f55d0594cbe94340129a4ef&amp;id=79cb9d7cc2'
          method='post'
          id='mc-embedded-subscribe-form'
          name='mc-embedded-subscribe-form'
          class='validate'
          target='_blank'
          novalidate
        >
          <div id='mc_embed_signup_scroll'>
            <div class='mc-field-group form-row'>
              <div class='col-12'>
                <p>Get the latest live streaming tips via email.</p>
                <input
                  type='email'
                  value=''
                  name='EMAIL'
                  placeholder='Email Address'
                  class='required email form-control mb-2'
                  id='mce-EMAIL'
                />
              </div>
            </div>
            <div id='mce-responses' class='clear'>
              <div class='response' id='mce-error-response' style={{ display: 'none' }}></div>
              <div class='response' id='mce-success-response' style={{ display: 'none' }}></div>
            </div>
            <div style={{ position: ' absolute', left: '-5000px' }} aria-hidden='true'>
              <input type='text' name='b_87f55d0594cbe94340129a4ef_79cb9d7cc2' tabIndex='-1' />
              <div class='clear'>
                <input
                  type='submit'
                  value='Subscribe'
                  name='subscribe'
                  id='mc-embedded-subscribe'
                  class='button btn btn-primary btn-loading btn-block'
                />
              </div>
            </div>
          </div>
        </form>
      </div> */}
  </div>
);

export default Newsletter;
