import React from 'react';
import { months } from '../../../_utils/globalData';

const PopularPosts = ({ posts }) => {
  return (
    <div class='mb-4'>
      <h5>Popular</h5>
      <ul class='list-unstyled list-articles'>
        {posts.map(post => {
          let formattedDate =
            new Date(post.published_at).getDate() +
            ' ' +
            months[new Date(post.published_at).getMonth()] +
            ' ' +
            new Date(post.published_at).getFullYear();
          return (
            <li class='row row-tight'>
              {post.feature_image && (
                <a href={() => false} class='col-3'>
                  <img src={post.feature_image} alt={post.title} class='rounded' />
                </a>
              )}{' '}
              <div class='col'>
                <a href={post.url}>
                  <h6 class='mb-1'>{post.title}</h6>
                </a>
                <div class=' d-flex text-small'>
                  {post?.primary_tag && <a href={post.primary_tag.url}>{post.primary_tag.name}</a>}
                  &nbsp;
                  <span class='text-muted'>
                    <time dateTime={post.published_at.split('T')[0]}>{formattedDate}</time>
                  </span>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PopularPosts;
