import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageSection from '_components/PageSection';
import { Spinner } from 'react-bootstrap';
import ProfilePageLayout from '_components/Profile/PageLayout/ProfilePageLayout';
import { APP_API_URL } from '_constants/api';
import Logo from '_assets/images/logo-poly.svg';
import { Link } from 'react-router-dom';
import LogoFooter from '_assets/images/logo.svg';
import MetaTags from '_components/MetaTags';
import trackEvent from '_utils/events';

const DirectoryPage = () => {
  const { t } = useTranslation();

  const [userProfile, setUserProfile] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const fetchUsers = (data) => fetch(`${APP_API_URL}/api/magicLink/directory`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((el) => el.json())
    .then((res) => {
      setUserProfile(res.users);
      setLoading(false);
    });

  useEffect(() => {
    trackEvent({ event: 'Page - Directory' });

    fetchUsers();
  }, []);

  return (
    <>
      {loading ? (
        <ProfilePageLayout>
          <div className="text-center mx-5">
            <Spinner animation="border" role="status">
              <span className="sr-only">{t('filepreview.loading')}</span>
            </Spinner>
          </div>
        </ProfilePageLayout>
      ) : (
          <>
            {userProfile && (
              <>
                <MetaTags
                  title="Live Streamer Directory"
                  description="Find the top live streamers using StreamOn to reach new fans."
                />
                <PageSection>
                  <div className="row justify-content-center mb-md-3 mt-3">
                    <div className="col-auto">
                      <Link to="/">
                        <img height="143" src={Logo} alt="StreamOn" />
                      </Link>
                    </div>
                  </div>
                  <div className="row text-center pb-6 w-100">
                    <div className="col layer-2 w-100">
                      <h2 className="h1">{t('directory.title')}</h2>
                      <div className="lead mb-4">{t('directory.subtitle')}</div>

                      <Link to="/">
                        <button className="btn btn-primary">{t('directory.button')}</button>
                      </Link>
                    </div>
                  </div>
                  <div className="row justify-content-center mb-md-3 mt-3">
                    {userProfile.map((user) => (
                      <div key={user.magicLink} className="m-2 d-flex flex-column">
                        {' '}
                        <a
                          href={user.magicLink}
                          className="mt-1 mx-auto"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            className="avatar avatar-lg rounded-circle"
                            src={user.profilePicture}
                            alt={`${user.magicLink} Profile`}
                          />
                        </a>
                        <a
                          href={user.magicLink}
                          className="mt-1"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {user.name}
                        </a>
                        <a
                          href={user.magicLink}
                          className="mx-auto"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <small>{user.magicLink}</small>
                        </a>
                      </div>
                    ))}
                  </div>
                  <div className="row justify-content-center mb-md-6 mt-5">
                    <div className="col-auto">
                      <a href="https://streamon.app">
                        <img height="48" src={LogoFooter} alt="StreamOn" />
                      </a>
                    </div>
                  </div>
                </PageSection>
              </>
            )}
          </>
        )}
    </>
  );
};

export default DirectoryPage;
