import React from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import userSelectors from '_selectors/mainUser.selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const BillingAlert = () => {
  const { t } = useTranslation();

  const user = useSelector(userSelectors.getUserInfo);

  return (
    <>
      {user.subscriptionStatus === 'payment_failed' ? (
        <section className="pt-3 pb-1">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-xl-10">
                {/* TODO: only show this when the user has a failed payment */}
                {/* TODO: show acrss  */}
                <Alert variant="danger">
                  {t('billingalert.bill')}
                  {' '}
                  <Link to="subscription">{t('billingalert.updatemessage')}</Link>
                  .
                </Alert>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default BillingAlert;
