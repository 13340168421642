import React from 'react';
import {
  Router, Route, Switch,
} from 'react-router-dom';
import history from '_utils/history';
import PrivateRoute from '_components/PrivateRoute';
import Pages from '_pages';

// // FB Pixel
// import ReactPixel from 'react-facebook-pixel';

// const options = {
//   autoConfig: true, // set pixel's autoConfig
//   debug: false, // enable logs
// };

// ReactPixel.init('1271514113201724', options);

export const PATH = {
  SIGNUP: '/signup',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token',
  CONNECT: {
    url: '/connect',
    name: 'Connect',
  },
  GO_LIVE: {
    url: '/go-live',
    name: 'Go Live',
  },
  ENGAGE: {
    url: '/engage',
    name: 'Engage',
  },
  SETTINGS: {
    url: '/settings',
    name: 'Settings',
  },
  ONAIR: {
    url: '/onair',
    name: 'OnAir',
  },
  UPGRADE: {
    url: '/subscription',
    urlParams: '/subscription/:plan?/:period?',
    name: 'Subscription',
  },

  EMAIL: {
    url: '/emails/:token',
    name: 'Emails',
  },
};
const Routes = () => {
  
  const RedirectToAcquisitionMessage = () => window.location.replace("https://indie.tech/streamon-has-been-acquired/");

  return (
    <div>
      <Router history={history}>
        <Switch>
          {/* <Route exact path='/'>
              <Redirect to={PATH.CONNECT.url} />
            </Route> */}
          <Route exact path="/" component={Pages.HomePage} />
          <Route exact path="/signup" component={RedirectToAcquisitionMessage} /> {/* Pages.SignUpPage*/}
          <Route exact path="/login" component={RedirectToAcquisitionMessage} /> {/* Pages.LogInPage*/}
          <Route exact path="/forgot-password" component={RedirectToAcquisitionMessage} /> {/* Pages.ForgotPasswordPage*/}
          <Route exact path="/blog" component={Pages.Blog} />
          <Route exact path="/post/:slug" component={Pages.Posts} />
          <Route exact path="/tag/:slug" component={Pages.Tag} />
          <Route exact path="/author/:slug" component={Pages.Authors} />
          <Route exact path="/pricing" component={Pages.Pricing} />
          {/* <Route exact path="/Highlights" component={Pages.HighlightsPage} /> */}
          <Route exact path="/page/:slug" component={Pages.AutoGeneratedPages} />
          {/* TODO: dynamic page SEO Pages */}
          <Route exact path="/service/:slug" component={Pages.ServicesPage} />
          <Route exact path="/service/:slug/and/:slug1" component={Pages.ServiceCombinationsPage} />
          <Route exact path="/service/:slug/with/:slug1" component={Pages.ServiceIntegrationPage} />
          {/* APP Pages */}

          <PrivateRoute exact path={PATH.ONAIR.url} component={RedirectToAcquisitionMessage} /> {/* Pages.OnAir */}
          <PrivateRoute exact path={PATH.CONNECT.url} component={RedirectToAcquisitionMessage} /> {/* Pages.ConnectPage */}
          <PrivateRoute exact path={PATH.GO_LIVE.url} component={RedirectToAcquisitionMessage} /> {/* Pages.GoLivePage */}
          <PrivateRoute exact path={PATH.ENGAGE.url} component={RedirectToAcquisitionMessage} /> {/* Pages.EngagePage */}
          <PrivateRoute exact path={PATH.SETTINGS.url} component={RedirectToAcquisitionMessage} /> {/* Pages.SettingsPage */}
          <PrivateRoute exact path={PATH.UPGRADE.urlParams} component={RedirectToAcquisitionMessage} /> {/* Pages.UpgradePage */}
          <PrivateRoute
            exact
            strict
            path={PATH.EMAIL.url}
            component={Pages.EmailConfirmationPage}
          />

          <Route path={PATH.RESET_PASSWORD} component={Pages.ResetPasswordPage} />

          {/* Errors */}
          <Route path="/directory" exact strict component={RedirectToAcquisitionMessage} /> {/* Pages.DirectoryPage */}
          <Route path="/:slug" exact strict component={RedirectToAcquisitionMessage} /> {/* Pages.ProfilePage */}
          <Route exact path="/404" component={Pages.NotFoundPage} />

          <Route component={Pages.NotFoundPage} />

          {/* NOTE: we now send to the NotFoundPage based on ajax call in the Profile Component */}
          {/* <Route component={Pages.NotFoundPage} /> */}
        </Switch>
      </Router>
    </div>
  )
};

export default Routes;
