import newServicesTypes from '_actionTypes/services.types';

const INITIAL_STATE = {
  loading: false,
  errors: {},
  loadingStreamTitle: false,
  succesChangingTitle: false,
};
const serviceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case newServicesTypes.RESET_SERVICE:
      return INITIAL_STATE;
    case newServicesTypes.NEW_SERVICES_REQUEST:
      return {
        ...state,
        succesChangingTitle: false,
        loading: true,
        errors: {},
      };
    case newServicesTypes.NEW_SERVICES_SUCCESS: {
      return {
        ...state,
        succesChangingTitle: false,
        loading: false,
        errors: {
          message: action.payload.data.message,
        },
      };
    }
    case newServicesTypes.NEW_SERVICES_FAIL: {
      let errors = { error: 'Undefined Error' };
      if (action.error.response.data) {
        errors = action.error.response.data;
      }
      return {
        ...state,
        succesChangingTitle: false,
        loading: false,
        errors,
      };
    }
    case newServicesTypes.UPDATE_TITLE_REQUEST:
      return {
        ...state,
        succesChangingTitle: false,
        loadingStreamTitle: true,
        errors: {},
      };
    case newServicesTypes.UPDATE_TITLE_SUCCESS: {
      return {
        ...state,
        succesChangingTitle: true,
        loadingStreamTitle: false,
        errors: {
          message: action.payload.data.message,
        },
      };
    }
    case newServicesTypes.UPDATE_TITLE_FAIL: {
      let errors = { error: 'Undefined Error' };
      if (action.error.response.data) {
        errors = action.error.response.data;
      }
      return {
        ...state,
        succesChangingTitle: false,
        loadingStreamTitle: false,
        errors,
      };
    }
    case newServicesTypes.RTMP_DELETE_REQUEST:
    case newServicesTypes.RTMP_REQUEST:
      return {
        ...state,
        succesChangingTitle: false,
        loading: true,
        errors: {},
      };
    case newServicesTypes.RTMP_DELETE_SUCCESS:
      return {
        ...state,
        succesChangingTitle: false,
        loading: false,
        errors: {
          message: action.payload.data.message,
        },
      };
    case newServicesTypes.RTMP_SUCCESS:
      return {
        ...state,
        succesChangingTitle: false,
        loading: false,
        errors: {
          message: action.payload.data.message,
        },
      };

    case newServicesTypes.RTMP_DELETE_FAIL:
    case newServicesTypes.RTMP_FAIL: {
      let errors = { error: 'Undefined Error' };
      if (action.error.response.data) {
        errors = action.error.response.data;
      }
      return {
        ...state,
        succesChangingTitle: false,
        loading: false,
        errors,
      };
    }
    default:
      return state;
  }
};

export default serviceReducer;
