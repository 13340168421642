import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '_locales/en.json';
import translationES from '_locales/es.json';
import translationKR from '_locales/kr.json';
import translationCN from '_locales/cn.json';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  cn: {
    translation: translationCN,
  },
  kr: {
    translation: translationKR,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
