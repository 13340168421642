import React, { useState, useEffect } from 'react';
import api from '_GhostContentAPI/GhostContentAPI';
import { useParams } from 'react-router-dom';
import { months } from '../../_utils/globalData';
import history from '_utils/history';
import Header from '_components/_MarketingBlogPage/Header';
import Footer from '_components/_MarketingBlogPage/Footer';

//Assets
import twitterIcon from '_assets/assetsBlog/img/icons/social/twitter.svg';
import linkedinIcon from '_assets/assetsBlog/img/icons/social/linkedin.svg';
import facebookIcon from '_assets/assetsBlog/img/icons/social/facebook.svg';
import MetaTags from '_components/MetaTags';

const Posts = () => {
  const { slug } = useParams();
  console.log('Posts -> slug', slug);

  const [ghostPost, setGhostPost] = useState(undefined);

  console.log('Blog -> ghostPosts', ghostPost);
  const fetchPost = () => {
    api.posts
      .read({ slug }, { include: 'tags,authors' })
      .then(post => {
        setGhostPost(post);
        if (post === undefined) {
          history.push('/404');
        }
      })
      .catch(err => {
        console.error(err);
        history.push('/404');
      });
  };

  useEffect(() => {
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let formattedDate;
  if (ghostPost) {
    formattedDate =
      new Date(ghostPost.published_at).getDate() +
      ' ' +
      months[new Date(ghostPost.published_at).getMonth()] +
      ' ' +
      new Date(ghostPost.published_at).getFullYear();
  }

  return (
    <>
      {ghostPost ? (
        <>
          <MetaTags
            title={ghostPost.title}
            image={ghostPost.profile_image}
            description={ghostPost.excerpt}
          />
          <Header />
          <div class='article-progress' data-sticky='below-nav'>
            <progress class='reading-position' value='0'></progress>
            <div class='article-progress-wrapper'>
              <div class='container'>
                <div class='row'>
                  <div class='col py-2'>
                    <div class='d-flex justify-content-between align-items-center'>
                      <div class='d-flex'>
                        <div class='text-small text-muted mr-1'>Reading:</div>
                        <div class='text-small'>Navigating the complexity of change aversion</div>
                      </div>
                      <div class='d-flex align-items-center'>
                        <span class='text-small text-muted'>Share:</span>
                        <div class='d-flex ml-1'>
                          <a href={() => false} class='mx-1 btn btn-sm btn-round btn-primary'>
                            <img
                              class='icon'
                              src={twitterIcon}
                              alt='twitter social icon'
                              data-inject-svg
                            />
                          </a>
                          <a href={() => false} class='mx-1 btn btn-sm btn-round btn-primary'>
                            <img
                              class='icon'
                              src={linkedinIcon}
                              alt='linkedin social icon'
                              data-inject-svg
                            />
                          </a>
                          <a href={() => false} class='mx-1 btn btn-sm btn-round btn-primary'>
                            <img
                              class='icon'
                              src={facebookIcon}
                              alt='facebook social icon'
                              data-inject-svg
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class='bg-dark text-light overlay' data-overlay>
            {ghostPost?.feature_image && (
              <img srcSet={ghostPost.feature_image} alt='Feature' class='bg-image opacity-50' />
            )}

            <div class='container pt-6'>
              <div class='row justify-content-center'>
                <div class='col-lg-12 col-xl-10'>
                  <div class='d-flex justify-content-between align-items-center mb-3'>
                    <nav aria-label='breadcrumb'>
                      <ol class='breadcrumb'>
                        <li class='breadcrumb-item'>
                          <a href='/blog'>Blog</a>
                        </li>
                        <li class='breadcrumb-item'>
                          <a href={ghostPost.primary_tag.url}>{ghostPost.primary_tag.name}</a>
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <h1>{ghostPost.title}</h1>
                  <div class='d-flex align-items-center'>
                    <a href={ghostPost.authors[0].url}>
                      <img
                        src={ghostPost.authors[0].profile_image}
                        alt={ghostPost.authors[0].name}
                        class='avatar mr-2'
                      />
                    </a>

                    <div>
                      <div>
                        by <a href={ghostPost.authors[0].url}>{ghostPost.authors[0].name}</a>
                      </div>
                      <div class='text-small text-muted'>
                        <time dateTime={ghostPost.published_at.split('T')[0]}>{formattedDate}</time>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class='p-0' data-reading-position>
            <div class='container'>
              <div class='row justify-content-center'>
                <div class='col-xl-7 col-lg-8 col-md-10'>
                  <article
                    class='article py-5'
                    dangerouslySetInnerHTML={{ __html: ghostPost.html }}
                  ></article>
                </div>
              </div>
            </div>
          </section>{' '}
          <Footer />
        </>
      ) : null}
    </>
  );
};

export default Posts;
