import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import ProfilePageLayout from '_components/Profile/PageLayout/ProfilePageLayout';
import Pages from '_pages';
import { Spinner } from 'react-bootstrap';
import { APP_API_URL } from '_constants/api';
import { useTranslation } from 'react-i18next';
import trackEvent from '_utils/events';

const EmailConfirmationPage = () => {
  const { t } = useTranslation();

  const { token } = useParams();

  const [response, setResponse] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const verifyEmail = () => fetch(`${APP_API_URL}/api/emails/confirmation/${token}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((el) => el.json())
    .then((el) => {
      setResponse(el);
      setLoading(false);
      console.log('Success', el);
    });

  useEffect(() => {
    trackEvent({ event: 'Page - Email Confirmation' });
    verifyEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading ? (
        <ProfilePageLayout>
          <div className="text-center mx-5">
            <Spinner animation="border" role="status">
              <span className="sr-only">{t('filepreview.loading')}</span>
            </Spinner>
          </div>
        </ProfilePageLayout>
      ) : (
          <>
            {response && response.check === true ? (
              <Redirect to="/connect" />
            ) : (
                <Pages.NotFoundPage error={t('validation.emailConfirmation')} />
              )}
          </>
        )}
    </div>
  );
};

export default EmailConfirmationPage;
