import React, { useState, useEffect } from 'react';
import api from '_GhostContentAPI/GhostContentAPI';
import Aos from 'aos';
import { useParams } from 'react-router-dom';
import history from '_utils/history';
import Header from '_components/_MarketingBlogPage/Header';
import Footer from '_components/_MarketingBlogPage/Footer';
import MetaTags from '_components/MetaTags';

const AutoGeneratedPages = () => {
  const { slug } = useParams();

  const [ghostPage, setGhostPage] = useState(undefined);
  const fetchPages = () => {
    api.pages
      .read({ slug })
      .then((page) => {
        setGhostPage(page);
        if (page === undefined) {
          history.push('/404');
        }
      })
      .catch((err) => {
        console.error(err);
        history.push('/404');
      });
  };

  useEffect(() => {
    Aos.init();
    fetchPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {ghostPage && (
        <>
          <MetaTags
            title={ghostPage.title}
            description={ghostPage.excerpt}
            image={ghostPage.feature_image}
          />
          <Header />
          <section className="bg-dark text-light overlay" data-overlay>
            {ghostPage.feature_image && (
              <img
                src={ghostPage.feature_image}
                alt={ghostPage.title}
                className="bg-image opacity-50"
              />
            )}
            <div className="container pt-6">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-xl-10">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href={() => false}>{ghostPage.title}</a>
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <h1>{ghostPage.title}</h1>
                </div>
              </div>
            </div>
          </section>
          <section className="p-0" data-reading-position>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-8 col-md-10">
                  <article
                    className="article py-5"
                    dangerouslySetInnerHTML={{ __html: ghostPage.html }}
                  />
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )
      }
    </>
  );
};

export default AutoGeneratedPages;
