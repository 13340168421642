import React from 'react';
import { node } from 'prop-types';
import Logo from '_assets/images/logo.svg';

const ProfilePageLayout = ({ children }) => (
  <section className="min-vh-100 py-5">
    <div className="container">
      <div className="row justify-content-center pt-0">
        <div className="col-xl-4 col-lg-5 col-md-6">{children}</div>
      </div>
      <div className="row justify-content-center mb-md-6 mt-5">
        <div className="col-auto">
          <a href="https://streamon.dev">
            <img height="48" src={Logo} alt="StreamOn" />
          </a>
        </div>
      </div>
    </div>
  </section>
);

ProfilePageLayout.propTypes = {
  children: node.isRequired,
};

export default ProfilePageLayout;
