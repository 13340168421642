import React, { useState, useEffect } from 'react';
import api from '_GhostContentAPI/GhostContentAPI';
import PostCard from '_components/_MarketingBlogPage/PostCard/index';
import Newsletter from '_components/_MarketingBlogPage/Newsletter/index';
import PopularPosts from '_components/_MarketingBlogPage/PopularPosts';
import Header from '_components/_MarketingBlogPage/Header';
import Footer from '_components/_MarketingBlogPage/Footer';

// Assets
import blogHedaer from '_assets/assetsBlog/img/blog-header.jpg';

const Blog = () => {
  const [ghostPosts, setghostPosts] = useState([]);
  console.log('Blog -> ghostPosts', ghostPosts);
  const fetchPosts = () => {
    api.posts
      .browse({ include: 'tags,authors' })
      .then((posts) => setghostPosts(posts))
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <>
      <Header />
      <section
        className="has-divider text-light jarallax bg-dark"
        data-jarallax
        data-speed="0.5"
        data-overlay
      >
        <img src={blogHedaer} alt="" className="jarallax-img opacity-50" />
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-8">
              <h1 className="display-4">Blog</h1>
              <p className="lead mb-0">The latest live streaming news, tips and tricks.</p>
            </div>
          </div>
        </div>
      </section>
      <section data-overlay>
        <div className="container">
          <div className="row mb-4">
            <div className="col-md-8 col-lg-9">
              {/* For Each post post card component */}
              {ghostPosts.map((post) => (
                <PostCard key={post.id} post={post} />
              ))}
              {/* TODO: pagination */}
            </div>
            <div className="col-md-4 col-lg-3 d-none d-md-block sticky-top">
              <Newsletter />
              <PopularPosts posts={ghostPosts} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Blog;
