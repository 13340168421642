import React, { useState, useEffect } from 'react';
import PageLayout from '_components/PageLayout';
import PageSection from '_components/PageSection';
import {
  Form, Button, Alert, Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckOutForm from '_components/CheckOutForm/index';
import userSelectors from '_selectors/mainUser.selectors';
import {
  cancelSubscription,
  refreshErrorSubscription,
  stripeSubscription,
  showBilling,
  setUpdateCard,
} from '_actions/mainUser.actions';
import CustomModal from '_components/CustomModal/index';
import { useParams } from 'react-router-dom';
import Pages from '_pages';
import PoweredByStripe from '_components/Logos/PoweredByStripe';
import SecureSSL from '_components/Logos/SecureSSL';

// TODO: move this array to the config file...
const PLANS = process.env.NODE_ENV === 'production'
  ? [
    {
      id: undefined,
      name: 'FREE',
      uri: 'free',
      features: 'Limited to 720p, Video + StreamOn Branding.',
      pricing: {
        monthly: {
          id: undefined,
          price: 0,
        },
        yearly: {
          id: undefined,
          price: 0,
        },
      },
    },
    {
      id: 'prod_I3sOLcPT7kPqjA',
      name: 'Starter',
      uri: 'starter',
      features: '720p Video, Remove Branding.',
      pricing: {
        monthly: {
          id: 'price_1HTkdLLUDSEOkKvYZZyOzxYA',
          monthly_price: 10,
        },
        yearly: {
          id: 'price_1HTkdLLUDSEOkKvYfccBp7sn',
          price: 96,
          monthly_price: 96 / 12,
        },
      },
    },
    {
      id: 'prod_I3sXAW1hwOvwrC',
      name: 'Pro',
      uri: 'pro',
      features: '1080p Video, Remove Branding + Monthly Streamer Deals.',
      pricing: {
        monthly: {
          id: 'price_1HTkmjLUDSEOkKvYTIztXhr8',
          monthly_price: 20,
        },
        yearly: {
          id: 'price_1HTkmkLUDSEOkKvYFkOggwp0',
          price: 180,
          monthly_price: 180 / 12,
        },
      },
    },
    {
      id: 'prod_I3sYVTQRrBKH2q',
      name: 'Business',
      uri: 'business',
      features: '4k Video, Complete Transcoding Control.',
      pricing: {
        monthly: {
          id: 'price_1HTknQLUDSEOkKvYelWXy6Ki',
          price: 50,
          monthly_price: 50,
        },
        yearly: {
          id: 'price_1HTknQLUDSEOkKvYUhQmzE6Y',
          price: 480,
          monthly_price: 480 / 12,
        },
      },
    },
  ]
  : [
    {
      id: undefined,
      name: 'FREE',
      uri: 'free',
      features: 'Limited to 720p, Video + StreamOn Branding.',
      pricing: {
        monthly: {
          id: undefined,
          price: 0,
        },
        yearly: {
          id: undefined,
          price: 0,
        },
      },
    },
    {
      id: 'prod_HZuywOgAH0h4yW',
      name: 'Starter',
      uri: 'starter',
      features: '720p Video, Remove Branding.',
      pricing: {
        monthly: {
          id: 'price_1H0l84LUDSEOkKvYi2Gkj6fp',
          monthly_price: 10,
        },
        yearly: {
          id: 'price_1H0l85LUDSEOkKvYMG30UtTi',
          price: 96,
          monthly_price: 96 / 12,
        },
      },
    },
    {
      id: 'prod_HZuy4EfvoQcHze',
      name: 'Pro',
      uri: 'pro',
      features: '1080p Video, Remove Branding + Monthly Streamer Deals.',
      pricing: {
        monthly: {
          id: 'price_1H0l8XLUDSEOkKvYe9GNiEzk',
          monthly_price: 20,
        },
        yearly: {
          id: 'price_1H0l8XLUDSEOkKvYWXbCjVqS',
          price: 180,
          monthly_price: 180 / 12,
        },
      },
    },
    {
      id: 'prod_HZuy4EfvoQcHze765',
      name: 'Business',
      uri: 'business',
      features: '4k Video, Complete Transcoding Control.',
      pricing: {
        monthly: {
          id: 'price_1H0l8XLUDSEOkKvYe9GNiEzk75',
          price: 50,
          monthly_price: 50,
        },
        yearly: {
          id: 'price_1H0l8XLUDSEOkKvYWXbCjVqS756',
          price: 480,
          monthly_price: 480 / 12,
        },
      },
    },
  ];

// TODO: get from the config file...
const stripePromise = process.env.NODE_ENV === 'production'
  ? loadStripe('pk_live_3s3v47p1WSbm4GsMZgGoHEhE')
  : loadStripe('pk_test_3PGYTtfMcsMe1TBRXZyZJONZ');

const UpgradePage = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const user = useSelector(userSelectors.getUserInfo);
  const errorSubscription = useSelector(userSelectors.getErrorsSubscription);
  const getSuccessSubscription = useSelector(userSelectors.getSuccessSubscription);
  const loadingCancel = useSelector(userSelectors.loadingCancel);
  const hideBilling = useSelector(userSelectors.getShowBilling);

  const { plan, period } = useParams();

  let selectPlan;
  PLANS.forEach((el, i) => {
    if (el.name.toLowerCase() === plan) {
      selectPlan = i;
    }
  });

  // toggle modal
  const [showCancelSubs, setShowCancelSubs] = useState(false);
  const [showUpdateSubs, setShowUpdateSubs] = useState(false);
  const [subscription, setSubscription] = useState(true);
  const [subscriptionPrice, setSubscriptionPrice] = useState('');
  const [currentPeriod, setCurrentPeriod] = useState('monthly');
  const [planUri, setPlanUri] = useState(user.productName);

  const showModalUpdateSubscription = (priceId, uri) => {
    dispatch(refreshErrorSubscription());
    setShowUpdateSubs(!showUpdateSubs);
    if (user.subscriptionId) {
      dispatch(showBilling(false));
    }
    setSubscriptionPrice(priceId);
    setPlanUri(uri);
  };

  const changePaymentPeriod = () => {
    // Reset checkboxes
    setSubscription(!subscription);
    // if (subscriptionPrice === PLANS[1].pricing.monthly.id) {
    //   setSubscriptionPrice(PLANS[1].pricing.yearly.id);
    // } else if (subscriptionPrice === PLANS[1].pricing.yearly.id) {
    //   setSubscriptionPrice(PLANS[1].pricing.monthly.id);
    // } else if (subscriptionPrice === PLANS[2].pricing.monthly.id) {
    //   setSubscriptionPrice(PLANS[2].pricing.yearly.id);
    // } else if (subscriptionPrice === PLANS[2].pricing.yearly.id) {
    //   setSubscriptionPrice(PLANS[2].pricing.monthly.id);
    // }
    if (user.subscriptionId) {
      dispatch(showBilling(false));
    }

    if (currentPeriod === 'monthly') {
      setCurrentPeriod('yearly');
    } else if (currentPeriod === 'yearly') {
      setCurrentPeriod('monthly');
    }

    dispatch(refreshErrorSubscription());
  };

  useEffect(() => {
    dispatch(refreshErrorSubscription());
    dispatch(showBilling(false));

    if (plan && period) {
      if ((period === 'monthly' || period === 'yearly') && (plan === 'pro' || plan === 'starter')) {
        setCurrentPeriod(period);
        const initialPrice = PLANS[selectPlan].pricing[period].id;
        if (initialPrice) {
          setSubscriptionPrice(initialPrice);
          if (user.subscriptionId) {
            dispatch(showBilling(false));
          } else {
            dispatch(showBilling(true));
          }
          if (period === 'monthly') {
            setSubscription(false);
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showBillingUpgrade = (price, uri) => {
    dispatch(showBilling(true));
    setSubscriptionPrice(price);
    setPlanUri(uri);
  };

  const showUpdateCard = () => {
    dispatch(showBilling(true));
    dispatch(refreshErrorSubscription());
    dispatch(setUpdateCard(true));
  };

  const cancelSubscriptionOnModal = () => {
    setSubscriptionPrice('');
    dispatch(cancelSubscription(user.subscriptionId));
  };

  // return (
  //   <PageLayout>
  //     <PageSection title='Upgrade Plan'>
  //       <article class='article py-5'>
  //         <p>
  //           During the BETA StreamOn is FREE to use with unlimited forwarding channels. In future
  //           we'll include subtle branding on free accounts and launch paid plans to cover the costs
  //           to run the stream forwarding servers.
  //         </p>
  //         <p>
  //           Ideas on what you'd like to see in paid plans? Email{' '}
  //           <a href='mailto:ben@indie.tech'>ben@indie.tech</a>.
  //         </p>
  //       </article>
  //     </PageSection>
  //   </PageLayout>
  // );

  return (
    <>
      {(!period && !plan)
      || ((period === 'monthly' || period === 'yearly') && (plan === 'pro' || plan === 'starter')) ? (
        <PageLayout>
          <PageSection title={t('Upgrade Subscription')}>
            <div className="mb-3">
              <div>
                <div className="card card-default">
                  <div className="card-header">
                    <div className="float-left">{t('elements.button.subscribe')}</div>
                    <div className="float-right">
                      <strong className="mr-2">Save up to 15%:</strong>
                      <Form.Check
                        style={{ display: 'inline-block' }}
                        type="switch"
                        name="active"
                        id="monthly-yearly-switch"
                        checked={!subscription}
                        label={subscription ? 'Monthly' : 'Yearly'}
                        onChange={() => changePaymentPeriod()}
                      />
                    </div>
                    <div className="clearfix" />
                  </div>
                  <div className="table-responsive">
                    <Form>
                      <table className="table table-responsive-sm table-valign-middle mb-0">
                        <thead />
                        <tbody>
                          {PLANS.map((plan, i) => (
                            <tr key={plan.id}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <strong>{plan.name}</strong>
                                </div>
                              </td>
                              <td>
                                {plan.features}
                                {' '}
                                <a href="https://streamon.dev/pricing" target="_blank" rel="noopener noreferrer">
                                  More info...
                                </a>
                              </td>
                              <td>
                                {plan.name !== 'FREE' && (
                                <span className="table-plan-text">
                                  <strong className="table-plan-price">
                                    $
                                    {plan.pricing[currentPeriod].monthly_price}
                                  </strong>
                                  {' '}
                                  {t('checkoutform.monthly')}
                                  {' '}
                                  <br />
                                  <strong>
                                    {currentPeriod === 'monthly'
                                      ? null
                                      : `($${plan.pricing[currentPeriod].price} Billed Yearly)`}
                                  </strong>
                                </span>
                                )}
                              </td>
                              <td>
                                {console.log('plan', user.priceProduct)}
                                {plan.uri !== 'free' ? (
                                  <>
                                    {user.priceProduct === plan.pricing[currentPeriod].id ? (
                                      <Button variant="secondary" disabled>
                                        Current Plan
                                      </Button>
                                    ) : (
                                      <>
                                        {user.subscriptionId ? (
                                          <Button
                                            variant="secondary"
                                            onClick={() => showModalUpdateSubscription(
                                              plan.pricing[currentPeriod].id,
                                              plan.uri,
                                            )}
                                            disabled={
                                                subscriptionPrice === plan.pricing[currentPeriod].id
                                              }
                                          >
                                            Switch
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="primary"
                                            onClick={() => showBillingUpgrade(
                                              plan.pricing[currentPeriod].id,
                                              plan.uri,
                                            )}
                                            disabled={
                                                subscriptionPrice === plan.pricing[currentPeriod].id
                                              }
                                          >
                                            {t('elements.button.upgrade')}
                                          </Button>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : null}
                              </td>
                            </tr>
                          ))}

                          <CustomModal
                            setShow={setShowCancelSubs}
                            show={showCancelSubs}
                            title={t('modal.cancelSubscription.title')}
                            body={t('modal.cancelSubscription.body')}
                            actionModalConfirm={() => cancelSubscriptionOnModal()}
                          />
                          <CustomModal
                            setShow={setShowUpdateSubs}
                            show={showUpdateSubs}
                            title={t('modal.updateSubscription.title')}
                            body={t('modal.updateSubscription.body')}
                            actionModalConfirm={() => dispatch(
                              stripeSubscription({
                                uri: planUri,
                                subscriptionId: user.subscriptionId,
                                priceId: subscriptionPrice,
                                url: 'update-subscription',
                              }),
                            )}
                            actionModalClose={() => setSubscriptionPrice('')}
                          />
                        </tbody>
                      </table>
                    </Form>
                  </div>
                </div>
                <div className=" my-2">
                  {user.subscriptionId ? (
                    <div className="mt-3">
                      <div className="card card-default">
                        <div className="card-header border rounded-top">
                          <div className="float-left">{t('checkoutform.current')}</div>
                          <div className="float-right">
                            {t('checkoutform.card')}
                            {user.card}
                          </div>
                          <div className="clearfix" />
                        </div>
                        <div className="card-body">
                          <Button
                            className="mr-3"
                            variant="secondary"
                            onClick={() => showUpdateCard()}
                          >
                            {t('elements.button.update1')}
                          </Button>
                          {loadingCancel}
                          <Button
                            className="mr-3"
                            variant="danger"
                            onClick={() => setShowCancelSubs(!showCancelSubs)}
                          >
                            {loadingCancel ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                role="status"
                                className="m-0"
                              />
                            ) : (
                              t('elements.button.subscribe1')
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {errorSubscription ? (
                    <Alert variant="danger" className="mb-0">
                      {t(errorSubscription)}
                    </Alert>
                  ) : null}
                  {getSuccessSubscription ? (
                    <Alert variant="success" className="mb-0">
                      {t(getSuccessSubscription)}
                    </Alert>
                  ) : null}
                </div>
                {hideBilling ? (
                  <>
                    <div className="mt-3">
                      <div className="card card-default">
                        <div className="card-header border rounded-top">
                          {t('checkoutform.billing')}
                          <PoweredByStripe />
                          <SecureSSL />
                        </div>

                        <div className="card-body">
                          <Elements stripe={stripePromise}>
                            <CheckOutForm uri={planUri} priceId={subscriptionPrice} />
                          </Elements>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            {user.invoices.length > 0 ? (
              <div className="mb-3">
                <div>
                  <div className="card card-default">
                    <div className="card-header">{t('checkoutform.invoices')}</div>
                    <div className="table-responsive">
                      <table className="table table-responsive-sm table-valign-middle mb-0">
                        <thead>
                          <th>{t('checkoutform.date')}</th>
                          <th>{t('checkoutform.amount')}</th>
                          <th>{t('checkoutform.download')}</th>
                        </thead>
                        <tbody>
                          {/* Instead of reverese maybe filter, sometimes weird behaivor */}
                          {user.invoices.reverse().map((invoice, i) => (
                            <tr key={invoice.id}>
                              {/* TODO: show invoice number if there is one? */}
                              {/* TODO: format this date in a nice format */}
                              <td>{new Date(invoice.date).toString().split(/(\d{2}:)/)[0]}</td>
                              <td>
                                $
                                {`${invoice.amount.slice(0, -2)}.${invoice.amount.slice(-2)}`}
                              </td>
                              <td>
                                <Button
                                  as="a"
                                  variant="primary"
                                  href={invoice.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {t('checkoutform.download')}
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </PageSection>
        </PageLayout>
        ) : (
          <Pages.NotFoundPage />
        )}
    </>
  );
};

export default UpgradePage;
