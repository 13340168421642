import React from 'react';

const SecureSSL = () => (
  <svg
    width="107px"
    height="26px"
    viewBox="0 0 107 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>ssl_secure_dark</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="ssl_secure_dark">
        <path
          d="M101.605042,26 L5.39495798,26 C2.41514286,26 0,23.314 0,20 L0,6 C0,2.686 2.41514286,0 5.39495798,0 L101.605042,0 C104.584857,0 107,2.686 107,6 L107,20 C107,23.314 104.584857,26 101.605042,26 Z"
          id="Shape"
          fill="#F78764"
          opacity="0.502"
        />
        <g
          id="noun_Secured-credit-card_3215750"
          transform="translate(8.000000, 4.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <g id="Group">
            <g>
              <path
                d="M19.8461538,2.05179103 L19.8461538,1.52789056 C19.8461538,0.703476673 19.1907877,0.0333155154 18.3846154,0.0333155154 L1.61538462,0.0333155154 C0.809230769,0.0333155154 0.153846154,0.703476673 0.153846154,1.52789056 L0.153846154,2.05179103 L19.8461538,2.05179103 Z"
                id="Path"
              />
              <rect
                id="Rectangle"
                x="0.153846154"
                y="2.5237621"
                width="19.6923077"
                height="1.90509233"
              />
              <path
                d="M0.153846154,4.90090101 L0.153846154,11.29452 C0.153846154,12.1188961 0.809230769,12.789095 1.61538462,12.789095 L8.87077538,12.789095 L8.87077538,11.8734586 C8.87077538,11.2032974 9.32001231,10.6589638 9.93231692,10.5330576 L9.93231692,9.11714441 C9.93231692,6.97756427 11.6369262,5.23444611 13.7323108,5.23444611 C15.8246338,5.23444611 17.5292431,6.97756741 17.5292431,9.11714441 L17.5292431,10.5330576 C18.1353877,10.6621134 18.5938646,11.215855 18.5938646,11.8734586 L18.5938646,12.7733847 C19.3015508,12.6695259 19.8461538,12.0465304 19.8461538,11.2945169 L19.8461538,4.90090101 L0.153846154,4.90090101 Z M7.28616923,9.17694629 L2.12000308,9.17694629 C1.99385846,9.17694629 1.88923385,9.06997562 1.88923385,8.94096076 C1.88923385,8.80879627 1.99385846,8.70497523 2.12000308,8.70497523 L7.28616923,8.70497523 C7.41231385,8.70497523 7.51693846,8.80879627 7.51693846,8.94096076 C7.51693846,9.06997877 7.41231692,9.17694629 7.28616923,9.17694629 Z M9.75692923,7.27335483 L2.12000308,7.27335483 C1.99385846,7.27335483 1.88923385,7.16949602 1.88923385,7.03736929 C1.88923385,6.90835443 1.99385846,6.80138376 2.12000308,6.80138376 L9.75692923,6.80138376 C9.88309231,6.80138376 9.98769846,6.90835443 9.98769846,7.03736929 C9.98769846,7.16949602 9.88309538,7.27335483 9.75692923,7.27335483 Z M13.7323077,7.12233038 C12.6553846,7.12233038 11.7784677,8.01587859 11.7784677,9.11714441 L11.7784677,10.5047897 L15.6830831,10.5047897 L15.6830831,9.11714441 C15.6830831,8.01587859 14.8092308,7.12233038 13.7323077,7.12233038 Z"
                id="Shape"
              />
            </g>
            <path
              d="M17.3289021,10.9388615 L17.14198,10.9388615 L17.14198,9.08901351 C17.14198,7.23611074 15.6772129,5.69620253 13.8202186,5.69620253 C11.9877452,5.69620253 10.4954081,7.21731229 10.4954081,9.08901351 L10.4954081,10.9388615 L10.308486,10.9388615 C9.82124563,10.9388615 9.43820225,11.3332002 9.43820225,11.830849 L9.43820225,17.1079718 C9.43820225,17.5993576 9.82124563,18 10.308486,18 L17.328896,18 C17.8100016,18 18.2022472,17.5993576 18.2022472,17.1079718 L18.2022472,11.830849 C18.2022472,11.333197 17.8100046,10.9388615 17.3289021,10.9388615 Z M14.5280828,15.5429989 C14.5587385,15.6869639 14.4484251,15.8246628 14.3043916,15.8246628 L13.3360671,15.8246628 C13.1920336,15.8246628 13.0817201,15.6869639 13.1123575,15.5429989 L13.2717097,14.7605125 C13.2870283,14.6822639 13.256391,14.6071483 13.2043032,14.5476606 C13.044951,14.3692693 12.9560911,14.1251242 12.9897958,13.8559551 C13.0357488,13.5022713 13.3084818,13.204939 13.6486182,13.136052 C14.1818299,13.0265101 14.6537333,13.442818 14.6537333,13.97176 C14.6537333,14.1939736 14.5709898,14.3942964 14.4330911,14.5476606 C14.3810033,14.6071483 14.3534334,14.6822639 14.368752,14.7573795 L14.5280828,15.5429989 Z M16.222679,10.9388615 L11.4147153,10.9388615 L11.4147153,9.08901351 C11.4147153,7.73375957 12.4933716,6.63518622 13.8202217,6.63518622 C15.1654731,6.63518622 16.222679,7.74942808 16.222679,9.08901351 L16.222679,10.9388615 L16.222679,10.9388615 Z"
              id="Shape"
            />
          </g>
        </g>
        <text
          id="SSL-Secure"
          fontFamily="LucidaGrande, Lucida Grande"
          fontSize="12"
          fontWeight="normal"
          fill="#FFFFFF"
        >
          <tspan x="36.6689453" y="16">
            SSL
          </tspan>
          <tspan x="55.9931641" y="16" fontFamily="CorsivaHebrew, Corsiva Hebrew">
            {' '}
          </tspan>
          <tspan x="58.9931641" y="16">
            Secure
          </tspan>
        </text>
        <line
          x1="37.1265823"
          y1="18.75"
          x2="96.1265823"
          y2="18.75"
          id="Line"
          stroke="#FFFFFF"
          strokeLinecap="square"
        />
      </g>
    </g>
  </svg>
);

export default SecureSSL;
