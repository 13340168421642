import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '_assets/images/logo.svg';
import useRouter from '_hooks/useRouter';
import classNames from 'classnames';

import { PATH } from '_routes/Routes';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import authActions from '_actions/auth.actions';
import userSelectors from '_selectors/mainUser.selectors';
import { infoUser } from '_actions/mainUser.actions';

import Gravatar from 'react-gravatar';
import BetaAlert from '_components/BetaAlert';
import BillingAlert from '_components/BillingAlert';
import { useTranslation } from 'react-i18next';
import SwitchLanguage from '_components/SwitchLanguage';
import DarkModeSwitch from '_components/DarkModeSwitch';
import LaunchOnAirButton from '_components/LaunchOnAirButton';
import mergeFlags from '_constants/flag';

const Header = () => {
  const { pathname } = useRouter();
  const { CONNECT, GO_LIVE, ENGAGE, SETTINGS, UPGRADE } = PATH;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.getUserInfo);

  //Will update the user in every refresh
  useEffect(() => {
    if (user && user._id) {
      dispatch(infoUser(user._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogOut = e => {
    // e.preventDefault();
    dispatch(authActions.logOutUser());
  };

  const navItemClass = currentPath => classNames('nav-item', { active: pathname === currentPath });

  return (
    <div className='navbar-container'>
      <nav className='navbar navbar-expand-lg navbar-light'>
        <div className='container'>
          <div className='navbar-brand fade-page'>
            <Link to='/'>
              <img src={Logo} alt='StreamOn' />
              <span className='badge badge-secondary ml-2'>BETA 4</span>
            </Link>
          </div>
          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='.navbar-collapse'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <i className='fas fa-bars icon navbar-toggler-open' />
            <i className='fas fa-times icon navbar-toggler-close' />
          </button>
          <div className='collapse navbar-collapse justify-content-center'>
            <div className='py-2 py-lg-0'>
              <ul className='navbar-nav'>
                <li className={navItemClass(CONNECT.url)}>
                  <Link to={CONNECT.url} className='nav-link nav-link-active'>
                    {CONNECT.name}
                  </Link>
                </li>
                <li className={navItemClass(GO_LIVE.url)}>
                  <Link to={GO_LIVE.url} className='nav-link nav-link-active'>
                    {GO_LIVE.name}
                  </Link>
                </li>
                <li className={navItemClass(ENGAGE.url)}>
                  <Link to={ENGAGE.url} className='nav-link nav-link-active'>
                    {ENGAGE.name}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='collapse navbar-collapse justify-content-end nav-user'>
            {pathname !== '/go-live' && mergeFlags().onAir && (
              <div className='mr-2 mb-md-3'>
                <LaunchOnAirButton />
              </div>
            )}
            {mergeFlags().locale &&
              <div className='mr-2'>
                <SwitchLanguage />
              </div>}
            <Link className='text-white' to={UPGRADE.url}>
              {user.productName && user.productName !== 'free' ? (
                <>
                  <span className='badge badge-info'>
                    {user.productName}
                    <i className='fa fa-star ml-1' aria-hidden='true'></i>
                  </span>
                </>
              ) : (
                  <>
                    <div className='btn btn-info'>{t('elements.button.upgrade')}</div>
                  </>
                )}
            </Link>
            <div className='py-2 py-lg-0'>
              <ul className='navbar-nav'>
                <li className='nav-item dropdown dropleft'>
                  <span
                    className='nav-link dropdown-toggle'
                    id='dropdown05'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <i className='fas fa-caret-down pr-2' />
                    {/* TODO: replace with user image uploaded to the magicLink page or settings? */}
                    <Gravatar
                      email={user?.email}
                      size={100}
                      rating='pg'
                      className='avatar avatar-sm'
                    />
                  </span>
                  <div className='dropdown-menu' aria-labelledby='dropdown05'>
                    <h6 className='dropdown-item'>{user.name}</h6>
                    <Link className='dropdown-item' to={`/${user.magicLink}`} target='_blank'>
                      <i className='fas fa-external-link-alt'></i> Magic Link
                    </Link>
                    <Link className='dropdown-item' to={UPGRADE.url}>
                      <i className='fa fa-credit-card' aria-hidden='true'></i>{' '}
                      {t('header.subscription')}
                    </Link>
                    <Link className='dropdown-item' to={SETTINGS.url}>
                      <i className='fa fa-cog' aria-hidden='true'></i> {t('header.settings')}
                    </Link>
                    <hr />
                    <span className='dropdown-item'>
                      <DarkModeSwitch />
                    </span>
                    <hr />
                    <span className='dropdown-item'>
                      <Button variant='secondary' onClick={handleLogOut}>
                        {t('header.logout')}
                      </Button>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* Temp notice about the beta */}
      <section className='pt-3 pb-1'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-12 col-xl-10'>
              <BetaAlert />
            </div>
          </div>
        </div>
      </section>

      {/* Billing Warning if card expired etc */}
      <BillingAlert />
    </div>
  );
};

export default Header;
