import React from 'react';
import { months } from '../../../_utils/globalData';

const PostCard = ({ post }) => {
  let formattedDate =
    new Date(post.published_at).getDate() +
    ' ' +
    months[new Date(post.published_at).getMonth()] +
    ' ' +
    new Date(post.published_at).getFullYear();
  return (
    <div class='pr-lg-4'>
      {post.feature_image ? (
        <div class='card card-article-wide flex-md-row no-gutters'>
          <a href={post.url} class='col-md-4'>
            <img src={post.feature_image} alt='Feature' class='card-img-top' />
          </a>
          <div class='card-body d-flex flex-column col-auto p-4'>
            <div class='d-flex justify-content-between mb-3'>
              <div class='text-small d-flex'>
                {post?.primary_tag && (
                  <div class='mr-2'>
                    <a href={post.primary_tag.url}>{post.primary_tag.name}</a>
                  </div>
                )}
                <span class='text-muted'>
                  <time dateTime={post.published_at.split('T')[0]}>{formattedDate}</time>
                </span>
              </div>
            </div>
            <a href={post.url} class='flex-grow-1'>
              <h3>{post.title}</h3>
            </a>

            <div class='d-flex align-items-center mt-3'>
              <img src={post.authors[0].profile_image} alt='Author' class='avatar avatar-sm' />
              <div class='ml-1'>
                <span class='text-small text-muted'>By &nbsp;</span>
                <span class='text-small'>{post.authors[0].name}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
          <a href={post.url} class='card card-body justify-content-between bg-primary text-light'>
            <div class='d-flex justify-content-between mb-3'>
              <div class='text-small d-flex'>
                <div class='mr-2'>{post?.primary_tag?.name}</div>
                <span class='text-muted'>
                  <time dateTime={post.published_at.split('T')[0]}>{formattedDate}</time>
                </span>
              </div>
            </div>
            <div>
              <h2>{post.title}</h2>
            </div>
            <div class='d-flex align-items-center mt-3'>
              <img src={post.authors[0].profile_image} alt='Author' class='avatar avatar-sm' />
              <div class='ml-1'>
                <span class='text-small text-muted'>By &nbsp;</span>
                <span class='text-small'>{post.authors[0].name}</span>
              </div>
            </div>
          </a>
        )}
    </div>
  );
};

export default PostCard;
