import React from 'react';
import { string, node } from 'prop-types';
import Logo from '_assets/images/logo-poly.svg';
import BetaAlert from '_components/BetaAlert';
import { useTranslation } from 'react-i18next';
import SwitchLanguage from '_components/SwitchLanguage';
import mergeFlags from '_constants/flag';


const AuthPageLayout = ({ title, subtitle, children }) => {
  const { t } = useTranslation();

  return (
    <section className="min-vh-100 py-5">
      <div className="container">
        {mergeFlags().locale &&
          <div className="d-flex flex-row-reverse">
            <SwitchLanguage />
          </div>
        }
        <div className="row justify-content-center mb-md-6">
          <div className="col-auto text-center">
            <a href="https://streamon.dev">
              <img height="143" src={Logo} alt="StreamOn" />

              <br />
              <span className="badge badge-secondary mt-2">BETA 4</span>
            </a>
          </div>
        </div>
        <div className="row justify-content-center pt-0">
          <div className="col-xl-4 col-lg-5 col-md-6">
            <BetaAlert />
          </div>
        </div>
        <div className="row justify-content-center pt-0">
          <div className="col-xl-4 col-lg-5 col-md-6">
            <div className="text-center mb-4">
              <h1 className="mb-1">{title}</h1>
              <span>{subtitle}</span>
            </div>
            {children}
          </div>
        </div>

        <div className="row justify-content-center mt-4 pt-0">
          <div className="col-xl-4 col-lg-5 col-md-6">
            <div className="text-small text-center mb-4">
              <a href="https://streamon.dev/privacy-policy/" target="_blank" rel="noopener noreferrer">
                {t('footer.privacyPolicy')}
              </a>
              {' '}
              |
              {' '}
              <a href="https://streamon.dev/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
                {t('footer.termsAndConditions')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

AuthPageLayout.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  children: node.isRequired,
};

export default AuthPageLayout;
