export default {
  LOGIN_REQUEST: 'AUTH/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH/LOGIN_SUCCESS',
  LOGIN_FAIL: 'AUTH/LOGIN_FAIL',

  SIGNUP_REQUEST: 'AUTH/SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'AUTH/SIGNUP_SUCCESS',
  SIGNUP_FAIL: 'AUTH/SIGNUP_FAIL',

  SET_CURRENT_USER: 'AUTH/SET_CURRENT_USER',

  FORGOT_PASSWORD_REQUEST: 'AUTH/FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'AUTH/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAIL: 'AUTH/FORGOT_PASSWORD_FAIL',

  RESET_PASSWORD_REQUEST: 'AUTH/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'AUTH/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAIL: 'AUTH/RESET_PASSWORD_FAIL',

  RESET_AUTH: 'AUTH/RESET_AUTH_STORE',
};
