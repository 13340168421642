import authTypes from '_actionTypes/auth.types';

const isEmpty = require('is-empty');

const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  errors: {},
  check: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case authTypes.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };

    case authTypes.LOGIN_REQUEST:
    case authTypes.SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
      };

    case authTypes.LOGIN_SUCCESS:
    case authTypes.SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: {},
      };
    }

    case authTypes.LOGIN_FAIL:
    case authTypes.SIGNUP_FAIL: {
      // Log the errors into Redux here
      return {
        ...state,
        loading: false,
        errors: action.error.response.data ? action.error.response.data : { error: 'Undefined Error' },
      };
    }

    case authTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
      };

    case authTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: {},
        check: action.payload.data,
      };
    }

    case authTypes.RESET_PASSWORD_FAIL: {
      let errors = { error: 'Undefined Error' };
      if (action.error.response.data) {
        errors = action.error.response.data;
      }

      // Log the errors into Redux here
      return {
        ...state,
        loading: false,
        errors,
      };
    }
    case authTypes.RESET_AUTH:
      return initialState;

    default:
      return state;
  }
}
