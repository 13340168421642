import React from 'react';
import { node, string } from 'prop-types';

const PageSection = ({ title, children }) => (
  <section className="p-0">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-12 col-xl-10">
          {title && <h1>{title}</h1>}
          {children}
        </div>
      </div>
    </div>
  </section>
);

PageSection.propTypes = {
  title: string.isRequired,
  children: node.isRequired,
};

PageSection.defaultProps = {
  title: null,
};

export default PageSection;
