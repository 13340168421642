import axios from 'axios';

import { APP_API_URL } from '_constants/api';
import { store } from '_store';
import authActions from '_actions/auth.actions';

const instance = axios.create({
  baseURL: APP_API_URL,
  responseType: 'json',
  // Option to include cookies in the headers with axios
  withCredentials: true,
});
// When the Authoritzation middleware fails logthe user out
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // place your reentry code
      store.dispatch(authActions.logOutUser());
    }
    return error;
  },
);

const client = {
  default: {
    client: instance,
  },
};

export default client;
