import magicLinkType from '_actionTypes/magicLink.types';
import { API_URLS } from '_constants/api';
import { updateUser } from '_actions/mainUser.actions';

const changeMagicLinkRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      magicLinkType.MAGICLINK_REQUEST,
      magicLinkType.MAGICLINK_SUCCESS,
      magicLinkType.MAGICLINK_FAIL,
    ],
    payload: {
      request: {
        url: `${API_URLS.magicLink}`,
        method: 'post',
        data: {
          ...formValues,
        },
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};
export const updateMagicLink = (formValues) => (dispatch) => {
  dispatch(changeMagicLinkRequest(formValues))
    .then((res) => dispatch(updateUser(res.payload.data.user)))
    .catch(() => { });
};
const paypalSettingsRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      magicLinkType.PAYPAL_SETTINGS_REQUEST,
      magicLinkType.PAYPAL_SETTINGS_SUCCESS,
      magicLinkType.PAYPAL_SETTINGS_FAIL,
    ],
    payload: {
      request: {
        url: `${API_URLS.magicLink + formValues.url}`,
        method: 'post',
        data: {
          ...formValues,
        },
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};
export const paypalSettings = (formValues) => (dispatch) => {
  dispatch(paypalSettingsRequest(formValues))
    .then((res) => dispatch(updateUser(res.payload.data.user)))
    .catch(() => { });
};
const shareToWatchRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      magicLinkType.SHARE_EMAIL_REQUEST,
      magicLinkType.SHARE_EMAIL_SUCCESS,
      magicLinkType.SHARE_EMAIL_FAIL,
    ],
    payload: {
      request: {
        url: `${API_URLS.magicLink + formValues.url}`,
        method: 'post',
        data: {
          ...formValues,
        },
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};
export const shareToWatch = (formValues) => (dispatch) => {
  dispatch(shareToWatchRequest(formValues))
    .then((res) => dispatch(updateUser(res.payload.data.user)))
    .catch(() => { });
};

export const clearMagicLinkStore = () => ({
  type: magicLinkType.RESET_MAGICKLINK,
});

const importCsvRequest = (formValues) => (dispatch) => {
  const action = {
    types: [
      magicLinkType.IMPORT_CSV_REQUEST,
      magicLinkType.IMPORT_CSV_SUCCESS,
      magicLinkType.IMPORT_CSV_FAIL,
    ],
    payload: {
      request: {
        url: `${`${API_URLS.magicLink}/import/csv`}`,
        method: 'post',
        data: {
          ...formValues,
        },
        onError: () => {

        },
      },
    },
  };
  return dispatch(action);
};
export const importCSVRequest = (formValues) => (dispatch) => {
  dispatch(importCsvRequest(formValues))
    .then((res) => dispatch(updateUser(res.payload.data.user)))
    .catch(() => { });
};
