import { APP_API_URL } from '_constants/api';

const trackEvent = (obj) => fetch(`${APP_API_URL}/events`, {
  method: 'POST',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(obj),
});

export default trackEvent;
