import React from 'react';
import logoWhite from '_assets/assetsBlog/img/logo-white.svg';

const Footer = () => (
  <footer className="pb-5 bg-secondary text-light">
    <div className="container">
      <div className="row mb-4 justify-content-center">
        <div className="col-auto">
          <a href="/">
            <img src={logoWhite} alt="StreamOn" className="icon icon-lg" />
          </a>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <ul className="nav justify-content-center">
            <li className="nav-item">
              <a className="nav-link" href="/">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/pricing">
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://roadmap.streamon.dev">
                Roadmap
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/blog">
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/page/help">
                Help
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://indie.tech/streamon-terms-and-conditions/">
                Terms and Conditions
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://indie.tech/streamon-privacy-policy/">
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row justify-content-center mt-5 mb-5">
        <div className="col-auto">
          <ul className="nav">
            <li className="nav-item">
              <a className="nav-link" href="https://twitter.com/streamonapp">
                <i className="fab fa-twitter fa-lg" />
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://twitch.tv/streamonapp">
                <i className="fab fa-twitch fa-lg" />
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://facebook.com/streamonapp">
                <i className="fab fa-facebook fa-lg" />
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://feedly.com/i/subscription/feed/{{@site.url}}/rss/"
              >
                <i className="fas fa-rss fa-lg" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-xl-10">
          <small className="text-muted">
            ©2020 All Rights Reserved. StreamOn is a trading name of &nbsp;
            <a href="https://benbowler.limited" target="_blank" rel="noopener noreferrer">
              Ben Bowler Limited
            </a>
          </small>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
