module.exports = {
  // Stream Actions Icons
  buttonsAction: {
    facebook: {
      icon: 'fab fa-facebook-f',
    },
    youtube: {
      icon: 'fab fa-youtube',
    },
    twitch: {
      icon: 'fab fa-twitch',
    },
    email: {
      icon: 'fa fa-envelope',
    },
  },

  // Type of actions in engage page
  actionList: [
    {
      id: 0,
      service: 'facebook',
      icon: 'fab fa-facebook-square',
      title: 'Share to Facebook',
      body: 'Share a pre-defined message on your Facebook',
      subtitle: '',
      textInputRequired: true,
    },
    {
      id: 1,
      service: 'twitch',
      icon: 'fab fa-twitch',
      title: 'Share to Twitch Chat',
      body: 'Share a pre-defined message on Twitch Chat',
      subtitle: '',
      textInputRequired: true,
    },
    {
      id: 2,
      service: 'youtube',
      icon: 'fab fa-youtube',
      title: 'Share to Youtube Chat',
      body: 'Share a pre-defined message on Youtube Chat',
      subtitle: '',
      textInputRequired: true,
    },
    {
      id: 3,
      service: 'email',
      icon: 'fa fa-envelope',
      title: 'Email Subscribers',
      body: 'Send a pre-defined message to your email subscribers',
      subtitle: '',
      textInputRequired: true,
    },
  ],

  // Months Marketing Page
  months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  // Featured Services
  availableServices: [
    {
      type: 'facebook',
      formatType: 'facebook',
      title: 'services.facebook.title',
      iconStyle: 'https://static.xx.fbcdn.net/rsrc.php/yD/r/d4ZIVX-5C-b.ico',
      description:
        'Create an account or log into Facebook. Connect with friends, family and other people you know. Share photos and videos, send messages and get updates.',
      text: 'services.facebook.text',
      buttonStyle: 'btn-provider btn-facebook',
      buttonText: 'services.facebook.button',
      websiteUrl: 'https://facebook.com/',
    },
    {
      type: 'youtube',
      formatType: 'youtube',
      title: 'services.youtube.title',
      iconStyle: 'https://www.youtube.com/s/desktop/9077396a/img/favicon_32.png',
      description:
        'Enjoy the videos and music you love, upload original content, and share it all with friends, family, and the world on YouTube.',
      text: 'services.youtube.text',
      buttonStyle: 'btn-provider btn-google',
      buttonText: 'services.youtube.button',
      websiteUrl: 'https://youtube.com/',
    },
    {
      type: 'twitch',
      formatType: 'twitch',
      title: 'services.twitch.title',
      iconStyle: 'https://static.twitchcdn.net/assets/favicon-32-d6025c14e900565d6177.png',
      description: "Twitch is the world's leading video platform and community for gamers.",
      text: 'Stream live to your Twitch channel.',
      buttonStyle: 'btn-provider btn-twitch',
      buttonText: 'services.twitch.button',
      websiteUrl: 'https://twitch.tv/',
    },
    {
      type: 'rtmpserver',
      formatType: 'rtmpserver',
      title: 'services.rtmp-server.title',
      iconStyle: 'fas fa-server',
      description: '',
      text: 'services.rtmp-server.text',
      buttonStyle: 'btn-secondary',
      buttonText: 'services.rtmp-server.button',
    },
  ],
};
