import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CustomModal = ({
  title, body, setShow, show, actionModalConfirm, actionModalClose,
}) => {
  const { t } = useTranslation();

  const handleSubmit = () => {
    setShow(!show);
    actionModalConfirm();
  };

  // Closes Modal and executes callback function
  const handleClose = () => {
    setShow(!show);
    actionModalClose();
  };
  return (
    <Modal show={show} onHide={() => setShow(!show)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        {/* <Button variant='secondary' onClick={() => setShow(!show)}>
          Close
        </Button> */}
        {actionModalClose ? (
          <Button variant="secondary" onClick={() => handleClose()}>
            {t('elements.button.close')}
          </Button>
        ) : (
          <Button variant="secondary" onClick={() => setShow(!show)}>
            {t('elements.button.close')}
          </Button>
        )}
        {/* If actionModalConfirm is not pass the Confirm button only closes the Modal */}
        {actionModalConfirm ? (
          <Button variant="primary" onClick={() => handleSubmit()}>
            {t('elements.button.confirm')}
          </Button>
        ) : (
          <Button variant="primary" onClick={() => setShow(!show)}>
            {t('elements.button.confirm')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
