import React, { useEffect } from 'react';
import Aos from 'aos';
import Header from '_components/_MarketingBlogPage/Header';
import Footer from '_components/_MarketingBlogPage/Footer';
import MetaTags from '_components/MetaTags';

const Pricing = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <MetaTags
        title="Pricing"
        description="Use all of the core features of StreamOn for FREE, upgrade to remove the StreamOn branding and increase your stream quality."
      />
      <Header />
      <section className="bg-primary-alt header-inner o-hidden">
        <div className="container">
          <div className="row my-3">
            <div className="col">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href={() => false}>Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    <a href="/pricing/">Pricing</a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row py-6 text-center justify-content-center align-items-center">
            <div className="col-xl-8 col-lg-10">
              <h1 className="display-4">Simple Pricing</h1>
              <p className="lead mb-0">
                Use all of the core features of StreamOn for FREE, upgrade to remove the StreamOn
                branding and increase your stream quality.
              </p>
            </div>
          </div>
        </div>
        <div className="decoration-wrapper d-none d-md-block">
          <div className="decoration left middle-y scale-2" data-jarallax-element="100">
            <img
              className="bg-primary"
              src="http://localhost:3001/assets/img/decorations/deco-dots-4.svg"
              alt="deco-dots-4 decoration"
              data-inject-svg
            />
          </div>
          <div className="decoration bottom right scale-2" data-jarallax-element="100 100">
            <img
              className="bg-primary-2"
              src="http://localhost:3001/assets/img/decorations/deco-blob-6.svg"
              alt="deco-blob-6 decoration"
              data-inject-svg
            />
          </div>
        </div>
        <div className="divider">
          <img
            src="http://localhost:3001/assets/img/dividers/divider-1.svg"
            alt="graphical divider"
            data-inject-svg
          />
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col text-center mb-4">
              <strong>Save up to 15% with Yearly Billing</strong>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <table className="table table-hover pricing-table table-responsive-md">
                <thead>
                  <tr>
                    <th scope="col" />
                    <th scope="col">
                      <span className="h4">FREE</span>
                      <div className="d-flex pt-md-3">
                        <span className="h3">$</span>
                        <span className="display-4">0</span>
                      </div>
                      <span className="text-small text-muted">Forever</span>
                      <a href="https://streamon.dev" className="btn d-block mt-4 btn-outline-primary">
                        Join
                      </a>
                    </th>
                    <th scope="col">
                      <span className="h4">Starter</span>
                      <div className="d-flex pt-md-3">
                        <span className="h3">$</span>
                        <span className="display-4">10</span>
                      </div>
                      <span className="text-small text-muted">Per Month</span>
                      <a
                        href="https://streamon.dev/subscription"
                        className="btn d-block mt-4 btn-outline-primary"
                      >
                        Upgrade
                      </a>
                    </th>
                    <th scope="col">
                      <span className="h4">
                        Pro
                        {' '}
                        <span className="ml-1 badge badge-primary">
                          <i className="fas fa-star" />
                          {' '}
                          Most Popular
                        </span>
                      </span>
                      <div className="d-flex pt-md-3">
                        <span className="h3">$</span>
                        <span className="display-4">20</span>
                      </div>
                      <span className="text-small text-muted">Per Month</span>
                      <a
                        href="https://streamon.dev/subscription"
                        className="btn d-block mt-4 btn-primary"
                      >
                        Upgrade
                      </a>
                    </th>
                    <th scope="col">
                      <span className="h4">Business</span>
                      <div className="d-flex pt-md-3">
                        <span className="h3">$</span>
                        <span className="display-4">50</span>
                      </div>
                      <span className="text-small text-muted">Per Month</span>
                      <a
                        href="https://streamon.dev/subscription"
                        className="btn d-block mt-4 btn-outline-primary"
                      >
                        Upgrade
                      </a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">Quality</span>
                    </th>
                    <td>
                      <span>
                        <strong>720p 30fps</strong>
                      </span>
                    </td>
                    <td>
                      <span>
                        <strong>720p 30fps</strong>
                      </span>
                    </td>
                    <td>
                      <span>
                        <strong>1080p 60fps</strong>
                      </span>
                    </td>
                    <td>
                      <span>
                        <strong>4k 60fps</strong>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">Bitrate</span>
                    </th>
                    <td>
                      <span>Unlimited</span>
                    </td>
                    <td>
                      <span>Unlimited</span>
                    </td>
                    <td>
                      <span>Unlimited</span>
                    </td>
                    <td>
                      <span>Unlimited</span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">Services</span>
                    </th>
                    <td>
                      <span>Unlimited</span>
                    </td>
                    <td>
                      <span>Unlimited</span>
                    </td>
                    <td>
                      <span>Unlimited</span>
                    </td>
                    <td>
                      <span>Unlimited</span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">Stream to 60+ Services</span>
                      <span className="text-small text-muted" />
                    </th>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">Facebook Pages/Groups</span>
                      <span className="text-small text-muted" />
                    </th>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span role='img' aria-label='Transcoding' className="h6 mb-0 d-block">JustWorks™️ Transcoding</span>
                      <span className="text-small text-muted" />
                    </th>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">Browser Streaming Studio</span>
                      <span className="text-small text-muted" />
                    </th>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">MagicLink Share URL</span>
                      <span className="text-small text-muted" />
                    </th>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">Commission Free Tipping</span>
                      <span className="text-small text-muted" />
                    </th>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">Email Subscriptions</span>
                      <span className="text-small text-muted" />
                    </th>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">Remove StreamOn Branding</span>
                      <span className="text-small text-muted" />
                    </th>
                    <td />
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">Monthly Streamer Deals</span>
                      <span className="text-small text-muted" />
                    </th>
                    <td />
                    <td />
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <th scope="row">
                      <span className="h6 mb-0 d-block">Full Transcoder Control</span>
                      <span className="text-small text-muted" />
                    </th>
                    <td />
                    <td />
                    <td />
                    <td>
                      <div className="icon-round icon-round-xs bg-success">
                        <img
                          className="icon bg-light"
                          src="http://localhost:3001/assets/img/icons/interface/check.svg"
                          alt="check interface icon"
                          data-inject-svg
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              <span>
                Need something new?
                {' '}
                <a href="mailto:ben@indie.tech">Get in touch</a>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-primary-alt">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-9">
              <h3 className="h2">Frequently Asked Questions</h3>
              <div className="my-4">
                <div
                  className="card mb-2 card-sm card-body hover-shadow-sm"
                  data-aos="fade-up"
                  data-aos-delay="NaN"
                >
                  <div
                    data-target="#panel-1"
                    className="accordion-panel-title"
                    data-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="panel-1"
                  >
                    <span className="h6 mb-0">Can I change plans later on?</span>
                    <img
                      className="icon"
                      src="http://localhost:3001/assets/img/icons/interface/plus.svg"
                      alt="plus interface icon"
                      data-inject-svg
                    />
                  </div>
                  <div className="collapse" id="panel-1">
                    <div className="pt-3">
                      <p className="mb-0">
                        Yes, you can change, cancel or update your card at any time in the
                        subscription page on your account.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="card mb-2 card-sm card-body hover-shadow-sm"
                  data-aos="fade-up"
                  data-aos-delay="NaN"
                >
                  <div
                    data-target="#panel-2"
                    className="accordion-panel-title"
                    data-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="panel-2"
                  >
                    <span className="h6 mb-0">What is the difference between Starter and Pro?</span>
                    <img
                      className="icon"
                      src="http://localhost:3001/assets/img/icons/interface/plus.svg"
                      alt="plus interface icon"
                      data-inject-svg
                    />
                  </div>
                  <div className="collapse" id="panel-2">
                    <div className="pt-3">
                      <p className="mb-0">
                        The Pro plan gives you access to our monthly streamer deals, offer codes and
                        giveaways of streaming gear from controllers to lights to graphics packs.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="card mb-2 card-sm card-body hover-shadow-sm"
                  data-aos="fade-up"
                  data-aos-delay="NaN"
                >
                  <div
                    data-target="#panel-3"
                    className="accordion-panel-title"
                    data-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="panel-3"
                  >
                    <span className="h6 mb-0">Can I try before I buy?</span>
                    <img
                      className="icon"
                      src="http://localhost:3001/assets/img/icons/interface/plus.svg"
                      alt="plus interface icon"
                      data-inject-svg
                    />
                  </div>
                  <div className="collapse" id="panel-3">
                    <div className="pt-3">
                      <p className="mb-0">
                        On our FREE plan you can try all of the StreamOn core features, with the
                        StreamOn branding. Once you're happy with your set-up, you can upgrade!
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="card mb-2 card-sm card-body hover-shadow-sm"
                  data-aos="fade-up"
                  data-aos-delay="NaN"
                >
                  <div
                    data-target="#panel-4"
                    className="accordion-panel-title"
                    data-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="panel-4"
                  >
                    <span className="h6 mb-0">How do I apply coupons codes and discounts?</span>
                    <img
                      className="icon"
                      src="http://localhost:3001/assets/img/icons/interface/plus.svg"
                      alt="plus interface icon"
                      data-inject-svg
                    />
                  </div>
                  <div className="collapse" id="panel-4">
                    <div className="pt-3">
                      <p className="mb-0">
                        The coupon field is available once you click the Upgrade button on the
                        payment form.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="card mb-2 card-sm card-body hover-shadow-sm"
                  data-aos="fade-up"
                  data-aos-delay="NaN"
                >
                  <div
                    data-target="#panel-5"
                    className="accordion-panel-title"
                    data-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="panel-5"
                  >
                    <span className="h6 mb-0">Can I have a refund?</span>
                    <img
                      className="icon"
                      src="http://localhost:3001/assets/img/icons/interface/plus.svg"
                      alt="plus interface icon"
                      data-inject-svg
                    />
                  </div>
                  <div className="collapse" id="panel-5">
                    <div className="pt-3">
                      <p className="mb-0">
                        We don't offer refunds but you can try all features for free, and you can
                        cancel at any time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <span>
                Still have questions?
                {' '}
                <a href="mailto:ben@indie.tech">Get in touch</a>
                {' '}
              </span>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Pricing;
