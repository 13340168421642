const getIsAuthenticated = (state) => state.auth.isAuthenticated;

const getUser = (state) => state.auth.user;

const getErrors = (state) => state.auth.errors;

const getConfirmationPassword = (state) => state.auth.check;

export default {
  getIsAuthenticated,
  getUser,
  getErrors,
  getConfirmationPassword,
};
