export default {
  NEW_SERVICES_REQUEST: 'NEW_SERVICES/NEW_SERVICES_REQUEST',
  NEW_SERVICES_SUCCESS: 'NEW_SERVICES/NEW_SERVICES_SUCCESS',
  NEW_SERVICES_FAIL: 'NEW_SERVICES/NEW_SERVICES_FAIL',

  UPDATE_TITLE_REQUEST: 'UPDATE_TITLE/UPDATE_TITLE_REQUEST',
  UPDATE_TITLE_SUCCESS: 'UPDATE_TITLE/UPDATE_TITLE_SUCCESS',
  UPDATE_TITLE_FAIL: 'UPDATE_TITLE/UPDATE_TITLE_FAIL',

  RTMP_REQUEST: 'RTMP/RTMP_REQUEST',
  RTMP_SUCCESS: 'RTMP/RTMP_SUCCESS',
  RTMP_FAIL: 'RTMP/RTMP_FAIL',
  RTMP_DELETE_REQUEST: 'RTMP_DELETE/RTMP_DELETE_REQUEST',
  RTMP_DELETE_SUCCESS: 'RTMP_DELETE/RTMP_DELETE_SUCCESS',
  RTMP_DELETE_FAIL: 'RTMP_DELETE/RTMP_DELETE_FAIL',

  SET_SERVICE: 'SET_SERVICE/SET_INITIAL_SERVICE',
  RESET_SERVICE: 'RESET_SERVICE/RESET_SERVICE_STORE',
};
