import PropTypes from 'prop-types';
import React from 'react';

import { MetaTags as ReactMetaTags } from 'react-meta-tags';

const MetaTags = ({ title = false, description = false, image = false }) => {

  // Fallback share image
  if (!image) {
    image = 'https://streamon-public-assets.s3.eu-west-1.amazonaws.com/share/share-image-0.png';
  }

  return (
    <ReactMetaTags>
      <title>
        {title && `${title} - `}
        StreamOn - Your Live Stream Starts Here
      </title>
      <meta
        name="description"
        content={
          description || 'Go live right from the browser, to YouTube, Twitch, Facebook and 60+ services. Increase your live stream quality. Reach new fans. Make Money. '
        }
      />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@streamonapp" />
      <meta name="twitter:title" content="StreamOn" />
      <meta
        name="twitter:description"
        content={
          description || 'Go live right from the browser, to YouTube, Twitch, Facebook and 60+ services. Increase your live stream quality. Reach new fans. Make Money. '
        }
      />
      <meta name="twitter:image" content={image} />
      {/* <meta name='twitter:creator' content={ghostAuthor.name} /> */}

      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      {/* <meta property='og:url' content={ghostAuthor.url} /> */}
      <meta property="og:image" content={image} />
      <meta
        property="og:description"
        content={
          description || 'Go live right from the browser, to YouTube, Twitch, Facebook and 60+ services. Increase your live stream quality. Reach new fans. Make Money. '
        }
      />
      <meta property="og:site_name" content="StreamOn" />
      {/* <meta property='fb:admins' content='Facebook numeric ID' /> */}
    </ReactMetaTags>
  );
};

MetaTags.propTypes = {
  description: PropTypes.bool.isRequired,
  image: PropTypes.bool.isRequired,
  title: PropTypes.bool.isRequired,
}

export default MetaTags;
