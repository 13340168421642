import React from 'react';
import { string, func, bool } from 'prop-types';
import { Form } from 'react-bootstrap';

const Input = ({
  id,
  name,
  label,
  value,
  type,
  placeholder,
  helperText,
  isInvalid,
  error,
  wrapperClassName,
  inputClassName,
  onChange,
  onBlur,
  disabled,
}) => (
  <Form.Group id={id} className={wrapperClassName}>
    {label && <Form.Label>{label}</Form.Label>}
    <Form.Control
      name={name}
      type={type}
      value={value}
      placeholder={placeholder}
      isInvalid={isInvalid}
      className={inputClassName}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
    {helperText && <Form.Text>{helperText}</Form.Text>}
    {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
  </Form.Group>
);

Input.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  label: string,
  value: string,
  type: string,
  placeholder: string,
  helperText: string,
  isValid: bool,
  wrapperClassName: string,
  inputClassName: string,
  onChange: func.isRequired,
  onBlur: func,
  disabled: bool,
};

Input.defaultProps = {
  label: null,
  value: '',
  type: 'text',
  placeholder: null,
  helperText: null,
  isInvalid: false,
  wrapperClassName: '',
  inputClassName: '',
  disabled: false,
  onBlur: () => {},
};

export default Input;
